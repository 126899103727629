import axios from "axios";
import { isMobile } from "mobile-device-detect";
import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import img from "../../Assets/Images/kanzaForMap.jpg";
import translate from "../../utils/translate";

export default function NearstStore() {
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getStores() {
    setLoading(true);
    axios
      .get(`https://kanza-back-end.onrender.com/stores`)
      .then((response) => {
        // console.log(response.data);
        setStores(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getStores();
  }, []);

  if (stores?.length === 0) {
    return (
      <>
        {loading ? (
          <div className="loading">
            {isMobile ? (
              <FallingLines
                color="#044F5C"
                width="150"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            ) : (
              <FallingLines
                color="#044F5C"
                width="300"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            )}
          </div>
        ) : (
          <section>
            <div className="container  py-5 mt-md-4"  dir="auto">
              <div className="row mt-3">
                <div className="col-md-12 main-cover">
                  <h2 className="cover-text">{translate("Nearest Stores")} </h2>
                </div>
                <div className="col-md-12 my-5 py-5">
                  <h1 className="text-center my-5 main-clr-text2 py-5">
                    {translate("No Stores Available")}
                  </h1>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  } else {
    return (
      <>
        {loading ? (
          <div className="loading">
            {isMobile ? (
              <FallingLines
                color="#044F5C"
                width="150"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            ) : (
              <FallingLines
                color="#044F5C"
                width="300"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            )}
          </div>
        ) : (
          <section>
            <div className="container py-5 mt-md-5"  dir="auto">
              <div className="row my-3 pt-2">
                <div className="col-md-12 main-cover mb-5">
                  <h2 className="cover-text">{translate("Nearest Stores")}</h2>
                </div>

                <div className="row">
                  {stores.map((store, i) => (
                    <div key={i} className="col-md-6 mb-4">
                      <div className="storeDiv rounded-4 d-flex  align-items-center py-4 ps-3">
                        <img
                          src={img}
                          width={100}
                          className="rounded-circle"
                          alt=""
                        />
                        <div>
                          <p className="fw-bold fs-5 ms-3">{sessionStorage.getItem("lang")==="ar"?store.name_ar:store.name_en}</p>
                          <div className="d-flex align-items-center">
                            <a
                              className="text-decoration-none text-black"
                              target="_blank"
                              href={store.location}
                            >
                              <i className=" ms-3 me-2 fa-solid fa-location-dot cursor-pointer"></i>
                              <span className=" p-0">{sessionStorage.getItem("lang")==="ar"?store.address_ar:store.address_en}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}
