import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import axios from "axios";
import { Vortex } from "react-loader-spinner";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import Payment from "../Payment/Payment";
import { isMobile } from "mobile-device-detect";
import translate from "../../utils/translate";

export default function CheckOut() {
  const [isErrorShown, setIsErrorShown] = useState(false);

  if (isErrorShown) {
    toast.dismiss();
  }

  const [apartment, setapartment] = useState(null);
  const [building, setbuilding] = useState(null);
  const [floor, setfloor] = useState(null);
  const [postalCode, setpostalCode] = useState(null);
  const [street, setstreet] = useState(null);
  const [city, setcity] = useState(null);
  const [shippingfee, setShippingfee] = useState(false);

  const [address, setAddress] = useState({
    apartment: apartment,
    building: building,
    floor: floor,
    postalCode: postalCode,
    street: street,
    city: city,
  });
  console.log(address);

  const addressString = JSON.stringify(address);

  localStorage.setItem("addressString", addressString);

  localStorage.setItem("shippingfee", shippingfee);

  let token = localStorage.getItem("token");
  const [addressshow, setAddressshow] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [country, setcountry] = useState(false);
  const [countries, setCountries] = useState([]);
  const [governments, setGovernments] = useState([]);
  const [government, setGovernment] = useState(false);
  const [governmentId, setGovernmentId] = useState("");
  const [allAddress, setAllAddress] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function addNewAddress() {
    document.getElementById("newAddress").click();
  }

  async function getAllAddress() {
    axios
      .get(`https://kanza-back-end.onrender.com/users/address`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setAllAddress(response?.data?.addresses);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getAllAddress();
    }
  }, []);

  let validationSchema = Yup.object({
    apartment: Yup.string().required("apartment is requrid "),
    floor: Yup.string().required("floor is requrid "),
    street: Yup.string().required("street is requrid "),
    building: Yup.string().required("building is requrid "),
    city: Yup.string().required("city is requrid "),
    postalCode: Yup.string().matches(
      /^[0-9]+$/,
      "Postal code must contain only digits"
    ),
  });

  let formik = useFormik({
    initialValues: {
      apartment: "",
      floor: "",
      street: "",
      building: "",
      city: governmentId,
      postalCode: "",
    },
    validationSchema,
    onSubmit: addAddres,
  });

  async function addAddres(values) {
    if (localStorage.getItem("token")) {
      setLoading(true);
      axios
        .post(`https://kanza-back-end.onrender.com/users/address`, values, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          toast.success(response.data.message);
          setLoading(false);
          getAllAddress();
          setShow(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
          setShow(false);
        });
    } else {
      setAddress(values);
      setShow(false);
    }
  }

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  async function getCountries() {
    axios
      .get(`https://kanza-back-end.onrender.com/zones/countries`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setCountries(response?.data?.countries);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCountries();
  }, []);

  async function getGovernorates(id) {
    axios
      .get(`https://kanza-back-end.onrender.com/zones/${id}/governorates`)
      .then((response) => {
        setGovernments(response.data.governorates);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <section className="mt-5 pt-5 mb-5 pb-5 container"  dir="auto">
        <div className="row">
          <div className="col-md-12 main-cover mt-3">
            <h2 className="cover-text">{translate("Checkout")} </h2>
          </div>
          <div className="d-flex w-75 mx-auto  justify-content-between mt-3">
            <Link className="text-decoration-none" to={"/cart"}>
              <p className="main-clr-text">{translate("Cart")}</p>
            </Link>
            <Link className="text-decoration-none" to={"/checkout"}>
              <p className="text-black ps-4">{translate("Checkout")}</p>
            </Link>
            {shippingfee ? (
              <Link className="text-decoration-none" to={"/payment"}>
                <p className="main-clr-text">{translate("Payment")}</p>
              </Link>
            ) : (
              <p
                className="main-clr-text cursor-pointer"
                onClick={() => {
                  toast.error("please select address first", {
                    duration: 1000,
                  });
                }}
              >
                {translate("Payment")}
              </p>
            )}
          </div>
          <div className="d-flex w-75 mx-auto justify-content-between mt-1 align-items-center mb-5 pb-3">
            <Link className="text-decoration-none" to={"/cart"}>
              <div className="cartCircle2"></div>
            </Link>

            <div className="cartLine"></div>
            <div className="cartCircle"></div>
            <div className="cartLine"></div>
            {shippingfee ? (
              <Link className="text-decoration-none" to={"/payment"}>
                <div className="cartCircle3"></div>
              </Link>
            ) : (
              <div
                className="cartCircle3 cursor-pointer"
                onClick={() => {
                  toast.error("please select address first", {
                    duration: 1000,
                  });
                }}
              ></div>
            )}
          </div>
          {isMobile ? (
            <div className=" w-100 ">
              <h4 className="fs-5">{translate("Select Address")} </h4>
            </div>
          ) : (
            <div className=" w-75 d-flex mx-auto mt-3">
              <h4 className="fs-5">{translate("Select Address")}</h4>
            </div>
          )}
          {address.city && !localStorage.getItem("token") && (
            <h5 className="mx-auto text-center">
              Your Address <br />
              Building: {address?.building + ","} Street: {address?.street},{" "}
              Apartment: {address?.apartment} , Floor: {address?.floor} , PostalCode: {address?.postalCode}
            </h5>
          )}
          {isMobile ? (
            <div className="d-flex justify-content-between w-100">
              {localStorage.getItem("token") && (
                <Dropdown className="w-100 checkoutdrop">
                  <Dropdown.Toggle
                    variant="success"
                    className="bg-white text-black btn-dark address-w d-flex justify-content-between align-items-center main-clr text-white"
                    id="dropdown-basic"
                  >
                    {addressshow
                      ? addressshow
                      : translate("Select Your address or add new address")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="address-w checkoutDropdown">
                    {allAddress.map((address) => (
                      <Dropdown.Item
                        className="w-100"
                        onClick={() => {
                          setAddressshow(
                            address?.building +
                              " ," +
                              address?.street +
                              " ," +
                              address?.city?.name +
                              " ," +
                              address?.apartment +
                              " , " +
                              address?.floor +
                              " , " +
                              address?.building +
                              " , " +
                              address?.postalCode
                          );
                          setapartment(address?.apartment);
                          setbuilding(address?.building);
                          setstreet(address?.street);
                          setcity(address?.city?._id);
                          setpostalCode(address?.postalCode);
                          setfloor(address?.floor);
                          setShippingfee(address?.city?.delivery);
                        }}
                      >
                        {address?.building + ","} {address?.street},{" "}
                        {address?.city?.name} ,{address?.apartment} ,{" "}
                        {address?.floor} , {address?.building}{" "}
                        {address?.postalCode}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <div className="w-100">
                <button
                  className="btn text-white py-1 main-clr ms-3 buttonaddnewaddress"
                  onClick={addNewAddress}
                >
                  +
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-end w-75 mx-auto">
              {localStorage.getItem("token") && (
                <Dropdown className="w-100 checkoutdrop">
                  <Dropdown.Toggle
                    variant="success"
                    className="bg-white text-black btn-dark address-w d-flex justify-content-between align-items-center main-clr text-white"
                    id="dropdown-basic"
                  >
                    {addressshow
                      ? addressshow
                      : translate("Select Your address or add new address")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="address-w checkoutDropdown">
                    {allAddress.map((address) => (
                      <Dropdown.Item
                        className="w-100"
                        onClick={() => {
                          setAddressshow(
                            address?.building +
                              " ," +
                              address?.street +
                              " ," +
                              address?.city?.name +
                              " ," +
                              address?.apartment +
                              " , " +
                              address?.floor +
                              " , " +
                              address?.building +
                              " , " +
                              address?.postalCode
                          );
                          setapartment(address?.apartment);
                          setbuilding(address?.building);
                          setstreet(address?.street);
                          setcity(address?.city?._id);
                          setpostalCode(address?.postalCode);
                          setfloor(address?.floor);
                          setShippingfee(address?.city?.delivery);
                          setAddress(address);
                        }}
                      >
                        {address?.building + ","} {address?.street},{" "}
                        {address?.city?.name} ,{address?.apartment} ,{" "}
                        {address?.floor} , {address?.building}{" "}
                        {address?.postalCode}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <div className="">
                <button
                  className="btn text-white py-1 main-clr ms-3 buttonaddnewaddress"
                  onClick={addNewAddress}
                >
                  +
                </button>
              </div>
            </div>
          )}
        </div>

        <Button
          variant="primary"
          id="newAddress"
          className="d-none"
          onClick={handleShow}
        >
          {translate("Launch demo modal")}
        </Button>

        <Modal className="mt-4" show={show} onHide={handleClose} dir="auto">
          <Modal.Header className="border-bottom-0" closeButton>
            <Modal.Title className="border-bottom-0 fw-bold">
              {translate("Add New Address")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form onSubmit={formik.handleSubmit}>
                {err ? toast.error(err) : ""}
                <Dropdown className="my-3 w-100">
                  <Dropdown.Toggle
                    className="main-clr w-100"
                    id="dropdown-basic"
                  >
                    {country ? country : translate("select country")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-100 overflow-overlay">
                    {countries.map((country) => (
                      <Dropdown.Item
                        onClick={() => {
                          setcountry(country.name);
                          getGovernorates(country._id);
                        }}
                      >
                        {sessionStorage.getItem("lang")==="ar"?country.nameAr:country.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="my-3 w-100">
                  <Dropdown.Toggle
                    className="main-clr w-100"
                    id="dropdown-basic"
                  >
                    {government ? government : translate("select government")}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className="w-100 overflow-scroll"
                    style={{ height: "50vh" }}
                  >
                    {governments.map((government) => (
                      <Dropdown.Item
                        onClick={() => {
                          setGovernment(government.name);
                          setGovernmentId(government._id);
                          setShippingfee(government?.delivery);
                          formik.setFieldValue("city", government._id);
                        }}
                      >
                        {sessionStorage.getItem("lang")==="ar"?government.nameAr:government.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <input
                  type="text"
                  placeholder={translate("apartment")}
                  name="apartment"
                  className="form-control py-2 mb-1"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.apartment && formik.touched.apartment ? (
                  <div class="error w-100 py-1">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.apartment}</div>
                  </div>
                ) : (
                  ""
                )}
                <input
                  type="number"
                  placeholder={translate("floor")}
                  className="form-control py-2 mt-3 my-1"
                  name="floor"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.floor && formik.touched.floor ? (
                  <div class="error w-100 py-1">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.floor}</div>
                  </div>
                ) : (
                  ""
                )}
                <input
                  type="text"
                  placeholder={translate("street")}
                  className="form-control py-2 mt-3 my-1"
                  name="street"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.street && formik.touched.street ? (
                  <div class="error w-100 py-1">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.street}</div>
                  </div>
                ) : (
                  ""
                )}
                <input
                  type="text"
                  placeholder={translate("building")}
                  className="form-control py-2 mt-3 my-1"
                  name="building"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.building && formik.touched.building ? (
                  <div class="error w-100 py-1">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.building}</div>
                  </div>
                ) : (
                  ""
                )}
                <input
                  type="number"
                  placeholder={translate("postalCode")}
                  className="form-control py-2 mt-3 my-1"
                  name="postalCode"
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {loading ? (
                  <button type="button" className="btn main-clr text-white">
                    <Vortex
                      visible={true}
                      height="30"
                      width="30"
                      ariaLabel="vortex-loading"
                      wrapperStyle={{}}
                      wrapperClass="vortex-wrapper"
                      colors={[
                        "red",
                        "green",
                        "blue",
                        "yellow",
                        "orange",
                        "purple",
                      ]}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn main-clr text-white mt-3"
                  >
                   {translate("Add new address")} 
                  </button>
                )}{" "}
              </form>
            </div>
          </Modal.Body>
        </Modal>

        <div className="text-end w-75 mx-auto mt-4">
          <Link to={"/cart"}>
            <button className="btn btn-outline-dark px-3 py-1 me-3 backButton">
              <i className="fa-solid fa-chevron-left"></i>
            </button>
          </Link>
          {shippingfee ? (
            <Link to={"/payment"}>
              <button className="btn main-clr text-white px-3 py-1">
                <i className="fa-solid fa-chevron-right"></i>
              </button>
            </Link>
          ) : (
            <button
              className="btn main-clr text-white px-3 py-1"
              onClick={() => {
                toast.error("select address first");
              }}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          )}
        </div>
      </section>

      <div className="d-none">
        <Payment address={address} />
      </div>
    </>
  );
}
