import React, { useState } from "react";
import Icon1 from "../../Assets/Icons/Frame 5.png";
import Icon2 from "../../Assets/Icons/Frame 6.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import toast from "react-hot-toast";
import { Vortex } from "react-loader-spinner";
import translate from "../../utils/translate";

export default function ContactUs() {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  let validationSchema = Yup.object({
    name: Yup.string().required("name is required"),
    email: Yup.string().required("email is required").email("email is invalid"),
    phone: Yup.string()
      .required("phone is requrid ")
      .matches(/^01[0-2]\d{8}$/, "Enter an Egyption phone number"),
    message: Yup.string().required("message is requrid "),
  });
  async function contactUs(values) {
    setLoading(true);
    axios
      .post(`https://kanza-back-end.onrender.com/contactUs/contact`, values)
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
      })
      .catch((err) => {
        setErr(err.response.data.message);
        toast.error(err.response.data.message);
        setLoading(false);
      });
  }

  let formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema,
    onSubmit: contactUs,
  });

  return (
    <>
      <section>
        <div className="container py-4 mt-md-5"  dir="auto">
          <div className="row mt-3 pt-2">
            <div className="col-md-12 main-cover mb-5">
              <h2 className="cover-text">{translate("Contact Us")} </h2>
            </div>
            <div className="col-md-5 mt-4 mx-auto me-3">
              <h6 className="fw-bold h5 mb-3">{translate("Contact us information")}</h6>
              <div className="d-flex align-items-center">
                <img
                  src={Icon1}
                  className=" me-2 mb-2 pb-1"
                  width={30}
                  alt="Icon1"
                />
                <a
                  href="mailto:kanzaegworld@gmail.com"
                  target="_blank"
                  className="mb-3 text-decoration-none text-dark"
                >
                  kanzaegworld@gmail.com
                </a>
              </div>
              <div className="d-flex align-items-center">
                <img
                  src={Icon2}
                  className=" me-2 mb-2 pb-1"
                  width={30}
                  alt="Icon1"
                />
                <a
                  href="tel:01157547852"
                  target="_blank"
                  className="mb-3 text-decoration-none text-dark"
                >
                  01157547852
                </a>
              </div>
            </div>
            <div className="col-md-5 mt-5 me-5 text-center"  dir="auto">
              <form  dir="auto" onSubmit={formik.handleSubmit}>
                <input
                  className="form-control py-2"
                  type="text"
                  name="name"
                  id="name"
                  placeholder={translate("Name")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.name && formik.touched.name ? (
                  <div class="error w-100 my-2 me-4">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.name}</div>
                  </div>
                ) : (
                  ""
                )}
                <input
                  className="form-control py-2 mt-2"
                  type="email"
                  name="email"
                  id="email"
                  placeholder={translate("Email")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div class="error w-100 my-2 me-4">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.email}</div>
                  </div>
                ) : (
                  ""
                )}

                <input 
                  className="form-control py-2 mt-2"
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder={translate("phone")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.phone && formik.touched.phone ? (
                  <div class="error w-100 my-2 me-4">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.phone}</div>
                  </div>
                ) : (
                  ""
                )}
                <textarea
                  className="form-control py-2 mt-2"
                  type="text"
                  name="message"
                  id="message"
                  required
                  placeholder={translate("Message")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  dir="auto"
                />
                {formik.errors.message && formik.touched.message ? (
                  <div class="error w-100 my-2 me-4">
                    <div class="error__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        viewBox="0 0 24 24"
                        height="24"
                        fill="none"
                      >
                        <path
                          fill="#393a37"
                          d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                        ></path>
                      </svg>
                    </div>
                    <div class="error__title">{formik.errors.message}</div>
                  </div>
                ) : (
                  ""
                )}
                {loading ? (
                  <button
                    type="button"
                    className="text-white btn main-clr mt-2 px-3 py-2 fw-bold"
                  >
                    <Vortex
                      visible={true}
                      height="30"
                      width="30"
                      ariaLabel="vortex-loading"
                      wrapperStyle={{}}
                      wrapperClass="vortex-wrapper"
                      colors={[
                        "red",
                        "green",
                        "blue",
                        "yellow",
                        "orange",
                        "purple",
                      ]}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="text-white btn main-clr mt-2 px-3 py-2 fw-bold"
                  >
                    {translate("Submit")}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
