import React, { useContext, useEffect, useState } from "react";
import mainImg from "../../Assets/Images/Cart Img.png";
import wishList1 from "../../Assets/Images/wishlist 1.png";
import wishList2 from "../../Assets/Images/wishlist 2.png";
import star from "../../Assets/Icons/star.png";
import axios from "axios";
import toast from "react-hot-toast";
import { CartContext } from "../Context/userContext";
import { FallingLines } from "react-loader-spinner";
import { isMobile } from "mobile-device-detect";
import { Link, useNavigate } from "react-router-dom";
import translate from "../../utils/translate";

export default function Wishlist() {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [wishlist, setWishlist] = useState([]);

  let { numberOfCartItems, setNumberOfCartItems } = useContext(CartContext);

  async function addToCart(productId, varianId) {
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToCart`,
        {
          productId: productId,
          variantId: varianId,
          quantity: 1,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        //   console.log(response);
        setNumberOfCartItems(response?.data?.cart?.products?.length);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getWishList() {
    if (localStorage.getItem("token")) {
      setLoading(true);

      let { data } = await axios.get(
        `https://kanza-back-end.onrender.com/users/wishlist`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setWishlist(data?.wishlist);
      setLoading(false);
    } else {
      navigate("/login");
    }
  }

  async function deletproduct(productid, varianId) {
    let { data } = await axios.delete(
      `https://kanza-back-end.onrender.com/users/removeFromWishlist/${productid}/${varianId}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    // console.log(data);
    getWishList();
  }

  useEffect(() => {
    getWishList();
  }, []);

  if (wishlist?.length === 0) {
    return (
      <>
        {loading ? (
          <div className="loading">
            {isMobile ? (
              <FallingLines
                color="#044F5C"
                width="150"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            ) : (
              <FallingLines
                color="#044F5C"
                width="300"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            )}
          </div>
        ) : (
          <section>
            <div className="container py-5 mt-md-4"  dir="auto">
              <div className="row mt-3">
                <div className="col-md-12 main-cover">
                  <h2 className="cover-text">{translate("WishList")} </h2>
                </div>
                <div className="col-md-12 my-5 py-5">
                  <h1 className="text-center my-5 main-clr-text2">
                   {translate("Your wishlist Is empty")}
                  </h1>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  } else {
    return (
      <>
        {loading ? (
          <div className="loading">
            {isMobile ? (
              <FallingLines
                color="#044F5C"
                width="150"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            ) : (
              <FallingLines
                color="#044F5C"
                width="300"
                visible={true}
                ariaLabel="falling-circles-loading"
              />
            )}
          </div>
        ) : (
          <section>
            <div className="container py-5 mt-md-4"  dir="auto">
              <div className="row mt-3">
                <div className="col-md-12 main-cover">
                  <h2 className="cover-text">{translate("WishList")} </h2>
                </div>
              </div>
              <div className="row my-5 pt-md-5">
                <div className="col-md-12">
                  {wishlist?.map((product, index) => (
                    <div key={index} className="products mt-5">
                      <div className="d-flex justify-content-between">
                        <Link
                          to={`/productdetails/${product._id}`}
                          className="text-decoration-none text-dark"
                        >
                          <div className="d-flex">
                            <img
                              src={product?.images[0]}
                              style={{ minWidth: "120px", maxWidth: "120px" }}
                              className="rounded-3"
                              alt="wishList1"
                            />
                            <div className="ms-3 me-3">
                              <h6>{sessionStorage.getItem("lang")==="ar"?product?.nameAr:product?.name}</h6>
                              <div className="d-flex flex-column h-100 justify-content-between">
                                {product.rating === 0 ? (
                                  ""
                                ) : (
                                  <p>
                                    {product?.rating}{" "}
                                    <span>
                                      <img
                                        src={star}
                                        width={15}
                                        alt="star"
                                        className="mb-1"
                                      />
                                    </span>
                                  </p>
                                )}
                                <p className="mb-5">
                                  {product.variant.price.toLocaleString()}  {sessionStorage.getItem("lang") === "en"
                              ? "EGP"
                              : "ج.م"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <div className=" d-flex">
                          <i
                            onClick={() => {
                              deletproduct(product?._id, product?.variant?._id);
                            }}
                            className="fa-solid fa-trash me-3 main-clr-text2 cursor-pointer ms-3"
                          ></i>
                          <i
                            onClick={() => {
                              addToCart(product?._id, product?.variant?._id);
                            }}
                            className="fa-solid fa-cart-shopping main-clr-text2 cursor-pointer"
                          ></i>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}
