import React from 'react'
import { Link } from 'react-router-dom'
import translate from '../../utils/translate'

export default function Sustainablefashion() {
  return <>
    <section>
            <div className='container py-5 mt-md-4'  dir="auto">
                <div className="row mt-md-4">
                    <div className="col-md-12 main-cover">
                        <h2 className='cover-text'>{translate("Sustainable Fashion")}  </h2>
                    </div>
                    <div className="col-md-12">
                        <p className='text-center mt-5 pb-5'>
                        {translate("At Kanza, we're not just passionate about style; we're committed to sustainability. That's why we're proud to announce that over 90% of our fashion line is crafted with the planet in mind. Through innovative dyeing and manufacturing techniques, we've revolutionized our production process to minimize environmental impact while maximizing style. From utilizing eco-friendly dyes to implementing energy-efficient practices, every step of our manufacturing journey is carefully curated to ensure we leave a lighter footprint on the planet. Join us in making a fashionable statement while championing sustainability. Explore our collection today and discover fashion that looks good and feels even better knowing it's environmentally friendly.")} 
                        </p>
                        <strong>{translate("If you have any questions or concerns, please")}  <Link className='text-black' to={'/contactus'}> {translate("contact us.")} </Link></strong>
                        <p className='fw-bold mb-5'>{translate("Thank you for choosing Kanza! We hope you enjoy your shopping experience.")} </p>
                    </div>
                </div>
            </div>
        </section>
  </>
}
