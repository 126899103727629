import React from "react";
import mainImg from "../../Assets/Images/Cart Img.png";
import frame from "../../Assets/Icons/Frame 99.png";
import translate from "../../utils/translate";

export default function About() {
  return (
    <>
      <section>
        <div className="container py-4 mt-md-5 aboutUs"  dir="auto">
          <div className="row mt-3">
            <div className="col-md-12 main-cover">
              <h2 className="cover-text">{translate("About Us")} </h2>
            </div>
            <div className=" col-md-12 mx-auto w-100">
              <div className="d-flex align-items-center ms-md-5 ms-0 flex-md-row flex-column mt-md-0 mt-5">
                <div className="col-md-7 ms-md-5 ms-0 col-12">
                  <h6 className="h4 border-bottom pb-2">{translate("Mission")}</h6>
                  <p className="mb-0 aboutusText">
                    {translate("Our mission is to make our customers feel comfortable, confident, and beautiful while wearing our products. As we believe that our clothing reflects our personalities, and we desire that everyone is able to express themselves. Additionally, we strive to offer the highest quality products at the most affordable prices.")}
                  </p>
                </div>
                <div className="col-lg-5 ms-md-5 mt-2 ms-0">
                  <img src={frame} className="aboutusimgs" width={400} alt="" />
                </div>
              </div>
              <div className="d-flex align-items-center ms-md-5 ms-0 flex-md-row-reverse flex-column mt-md-0 mt-5">
                <div className="col-md-7 ms-md-5 ms-0 col-12">
                  <h6 className="h4 border-bottom pb-2">{translate("Vission")}</h6>
                  <p className="mb-0 aboutusText">
                    {translate("Our vision is to be an industry leader, bringing fashion into the modern era while providing the best value to the consumer. Our goal is to consistently offer stylish and trendy collections.")}
                  </p>
                </div>
                <div className="col-lg-5 ms-md-5 mt-2 ms-0">
                  <img src={frame} className="aboutusimgs" width={400} alt="" />
                </div>
              </div>
              <div className="d-flex align-items-center ms-md-5 ms-0 flex-md-row flex-column mt-md-0 mt-5">
                <div className="col-md-7 ms-lg-5 ms-0">
                  <h6 className="h4 border-bottom pb-2">{translate("History")}</h6>
                  <p className="mb-0 aboutusText">
                    {translate("The company is a new business resulting from the fusion of three major companies specializing in textile, garment, and clothing manufacturing. It aims to provide youth with fashionable clothing options to buy and wear. As the company's founders, we discovered that the Egyptian fashion market is underserved. In fact, most clothing stores sell high-quality items at exorbitant prices. We saw a need for an Egyptian brand that offers high-quality, upbeat, fashion-forward clothing at reasonable prices.")}
                  </p>
                </div>
                <div className="col-md-3">
                  <img src={frame} className="aboutusimgs" width={400} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
