import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axios from "axios";
import { FallingLines, Vortex } from "react-loader-spinner";
import Dropdown from "react-bootstrap/Dropdown";
import { isMobile } from "mobile-device-detect";
import translate from "../../utils/translate";

export default function Addresses() {
  const [countryId, setCountryId] = useState(null);
  const [country, setcountry] = useState(false);
  const [countries, setCountries] = useState([]);
  const [governments, setGovernments] = useState([]);
  const [government, setGovernment] = useState(false);
  const [governmentId, setGovernmentId] = useState("");
  const [allAddress, setAllAddress] = useState([]);

  async function getGovernorates(id) {
    axios
      .get(`https://kanza-back-end.onrender.com/zones/${id}/governorates`)
      .then((response) => {
        setGovernments(response.data.governorates);
      })
      .catch((err) => console.log(err));
  }

  async function getCountries() {
    axios
      .get(`https://kanza-back-end.onrender.com/zones/countries`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setCountries(response?.data?.countries);
      });
  }

  useEffect(() => {
    getCountries();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);

  let validationSchema = Yup.object({
    apartment: Yup.string().required("apartment is requrid "),
    floor: Yup.string().required("floor is requrid "),
    street: Yup.string().required("street is requrid "),
    building: Yup.string().required("building is requrid "),
    city: Yup.string().required("city is requrid "),
    postalCode: Yup.string().required("postalCode is requrid "),
  });

  async function addAddres(values) {
    setLoading(true);
    // console.log(values);
    axios
      .post(`https://kanza-back-end.onrender.com/users/address`, values, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
        getAllAddress();
        setShow(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
        setShow(false);
      });
  }

  let formik = useFormik({
    initialValues: {
      apartment: "",
      floor: "",
      street: "",
      building: "",
      city: governmentId,
      postalCode: "",
    },
    validationSchema,
    onSubmit: addAddres,
  });

  function addNewAddress() {
    document.getElementById("newAddress").click();
  }

  async function getAllAddress() {
    setLoading(true);
    axios
      .get(`https://kanza-back-end.onrender.com/users/address`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setAllAddress(response?.data?.addresses);
        setLoading(false);
      });
  }

  useEffect(() => {
    getAllAddress();
  }, []);

  async function deleteAddress(id) {
    axios
      .delete(`https://kanza-back-end.onrender.com/users/address/${id}`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        toast.success(response?.data?.message);
        getAllAddress();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  return (
    <>
      {loading ? (
        <div className="loading">
          {isMobile ? (
            <FallingLines
              color="#044F5C"
              width="150"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          ) : (
            <FallingLines
              color="#044F5C"
              width="300"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          )}
        </div>
      ) : (
        <>
          <section className="pe-md-4 pe-5">
            {allAddress.length === 0 ? (
              <>
                <div className="text-center my-5 py-5">
                  <h3 className="fw-bold main-clr-text2">{translate("No address Yet")}  </h3>
                </div>
              </>
            ) : (
              allAddress.map((address) => (
                <div className="row border-bottom ms-md-2">
                  <div className="col-md-12">
                    <h3 className="bg-light py-3 ps-2">{translate("Address")}</h3>
                    <div className="d-flex justify-content-between">
                      <div className="mt-4">
                        <p className="font-md">
                          {address?.building} {address?.street},{" "}
                          {sessionStorage.getItem("lang")==="ar"?address?.city?.nameAr:address?.city?.name} ,{address?.apartment} ,{" "}
                          {address?.floor} , {address?.building} ,{" "}
                          {address?.postalCode}
                        </p>
                      </div>
                      <div className="d-flex flex-column mt-3 border-start ps-4 justify-content-center">
                        <button
                          className="btn btn-light px-5 mb-2"
                          onClick={() => {
                            deleteAddress(address?._id);
                          }}
                        >
                          {translate("Remove")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}

            <Button
              className="btn main-clr text-white mt-3"
              id="newAddress"
              onClick={handleShow}
            >
              {translate("Add New Address")}
            </Button>

            <Modal className="mt-4" dir="auto" show={show} onHide={handleClose}>
              <Modal.Header className="border-bottom-0" closeButton>
                <Modal.Title className="border-bottom-0 fw-bold">
                 {translate("Add New Address")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    {err ? toast.error(err) : ""}
                    <Dropdown className="my-3 w-100">
                      <Dropdown.Toggle
                        className="main-clr w-100"
                        id="dropdown-basic"
                      >
                        {country ? country : translate("select country")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-100">
                        {countries.map((country) => (
                          <Dropdown.Item
                            onClick={() => {
                              setcountry(country.name);
                              getGovernorates(country._id);
                            }}
                          >
                            {sessionStorage.getItem("lang")==="ar"?country.nameAr:country.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="my-3 w-100">
                      <Dropdown.Toggle
                        className="main-clr w-100"
                        id="dropdown-basic"
                      >
                        {government ? government : translate("select government")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-100 ">
                        {governments.map((government) => (
                          <Dropdown.Item
                            onClick={() => {
                              setGovernment(government.name);
                              setGovernmentId(government._id);
                              formik.setFieldValue("city", government._id);
                            }}
                          >
                            {sessionStorage.getItem("lang")==="ar"?government.nameAr:government.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <input
                      type="text"
                      placeholder={translate("apartment")}
                      name="apartment"
                      className="form-control py-2 mb-1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.apartment && formik.touched.apartment ? (
                      <div class="error w-100 py-1">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">
                          {formik.errors.apartment}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="number"
                      placeholder={translate("floor")}
                      className="form-control py-2 mt-3 my-1"
                      name="floor"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.floor && formik.touched.floor ? (
                      <div class="error w-100 py-1">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">{formik.errors.floor}</div>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      placeholder={translate("street")}
                      className="form-control py-2 mt-3 my-1"
                      name="street"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.street && formik.touched.street ? (
                      <div class="error w-100 py-1">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">{formik.errors.street}</div>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      placeholder={translate("building")}
                      className="form-control py-2 mt-3 my-1"
                      name="building"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.building && formik.touched.building ? (
                      <div class="error w-100 py-1">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">{formik.errors.building}</div>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="number"
                      placeholder={translate("postalCode")}
                      className="form-control py-2 mt-3 my-1"
                      name="postalCode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.postalCode && formik.touched.postalCode ? (
                      <div class="error w-100 py-1">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">
                          {formik.errors.postalCode}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {loading ? (
                      <button type="button" className="btn main-clr text-white">
                        <Vortex
                          visible={true}
                          height="30"
                          width="30"
                          ariaLabel="vortex-loading"
                          wrapperStyle={{}}
                          wrapperClass="vortex-wrapper"
                          colors={[
                            "red",
                            "green",
                            "blue",
                            "yellow",
                            "orange",
                            "purple",
                          ]}
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn main-clr text-white mt-3"
                      >
                      {translate("Add new address")}
                      </button>
                    )}{" "}
                  </form>
                </div>
              </Modal.Body>
            </Modal>
          </section>
        </>
      )}
    </>
  );
}
