import axios from "axios";
import { isMobile } from "mobile-device-detect";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FallingLines } from "react-loader-spinner";
import { Link, useHref } from "react-router-dom";
import eyeIcon from "../../Assets/Icons/eye-solid.svg";
import { Modal } from "react-bootstrap";
import translate from "../../utils/translate";
export default function Orders() {
  const [orders, setOrders] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = orders.slice(firstIndex, lastIndex);
  const npage = Math.ceil(orders.length / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  const [loading, setLoading] = useState(false);

  async function getOrders() {
    setLoading(true);
    let { data } = await axios.get(
      `https://kanza-back-end.onrender.com/users/myOrders`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    // console.log(data.orders);
    setOrders(data.orders);
    setLoading(false);
  }

  useEffect(() => {
    getOrders();
  }, []);

  // async function cancelOrder(id) {
  //   axios.delete(`https://kanza-back-end.onrender.com/users/cancelOrder/${id}` , {
  //     headers:{
  //       Authorization: localStorage.getItem('token')
  //     }
  //   }).then(response => {
  //     console.log(response);
  //     getOrders()
  //   }).catch(err =>{
  //     console.log(err);
  //   })
  // }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [onerOrderDetils, setOnerOrderDetils] = useState([])
  const clickOnerOrder = (order) => {
    console.log(order?.products);
    setOnerOrderDetils(order?.products)
    handleShow()
  } 

  return (
    <>
      {loading ? (
        <div className="loading">
          {isMobile ? (
            <FallingLines
              color="#044F5C"
              width="150"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          ) : (
            <FallingLines
              color="#044F5C"
              width="300"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          )}
        </div>
      ) : (
        <>
          {orders.lenght === 0 ? (
            <section className=" text-center">
              <h2 className="fw-bold">{translate("No orders yet")} </h2>
            </section>
          ) : (
            <section>
              <table className="table me-5 text-center">
                <thead className="bg-dark ">
                  <tr className="text-center">
                    <th scope="col">{translate("Order Details")}</th>
                    <th scope="col">{translate("Date")}</th>
                    <th scope="col">{translate("Status")}</th>
                    <th scope="col">{translate("Total")}</th>
                    {/* <th scope="col" >Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {records.map((order , index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={eyeIcon}
                          alt="eyeIcon"
                          width={25}
                          className="cursor-pointer"
                          onClick={()=>{clickOnerOrder(order)}}
                        />
                      </td>
                      <td>{order?.createdAt.split("T").slice(0, 1)}</td>
                      <td>{order.status}</td>
                      <td>{order?.total.toLocaleString()}   {sessionStorage.getItem("lang") === "en"
                              ? "EGP"
                              : "ج.م"} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {orders.length <= 5 ? (
                ""
              ) : (
                <div className="w-100 d-flex justify-content-center  ">
                  <nav>
                    <ul className="pagination">
                      <li className="page-item">
                        <span
                          className="page-link cursor-pointer"
                          onClick={prePage}
                        >
                      {translate("Previous")}    
                        </span>
                      </li>
                      {numbers.map((n, i) => (
                        <li
                          key={i}
                          className={`page-item cursor-pointer ${
                            currentPage === n ? "active" : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() => {
                              changeCPage(n);
                            }}
                          >
                            {n}
                          </span>
                        </li>
                      ))}
                      <li className="page-item">
                        <span
                          className="page-link cursor-pointer"
                          onClick={nextPage}
                        >
                          Next
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </section>
          )}
        </>
      )}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{translate("Order Details")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table">
            <thead>
              <tr>
                <th>{translate("Product Name")}</th>
                <th>{translate("Size")}</th>
                <th>{translate("Color")}</th>
                <th>{translate("Price")}</th>
              </tr>
            </thead>
              <tbody>
                {onerOrderDetils?.map((product , index) =>
                <tr key={index}>
                 <td><Link className="text-decoration-none text-black" to={`/productdetails/${product?.product?._id}`}>{sessionStorage.getItem("lang")==="ar"?product?.product?.nameAr:product?.product?.name}</Link></td>
                      <td>{product?.product?.variant?.size}</td>
                      <td>{product?.product?.variant?.color?.name}</td>
                      <td>{product?.product?.variant?.price} {sessionStorage.getItem("lang") === "en"
                              ? "EGP"
                              : "ج.م"} </td>
                    </tr>
                  
                )}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
  function prePage() {
    if (currentPage !== firstIndex) {
      setCurrentPage(currentPage - 1);
    }
  }
  function nextPage() {
    if (currentPage !== lastIndex) {
      setCurrentPage(currentPage + 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
}
