import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";

export default function CategorySlider() {
  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (current, next) => setImageIndex(next),
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const [categories, setCategories] = useState([]);
  async function getCategories() {
    let { data } = await axios.get(
      `https://kanza-back-end.onrender.com/products/categories`
    );
    setCategories(data.categories);
  }

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <div className="row mx-3 pe-3 pb-4"  dir="auto">
        <Slider {...settings}>
          {categories.map((category, idx) => (
            <div className="col-md-3 ">
              <Link
                to={`/shop`}
                className="text-decoration-none text-black"
                state={category}
              >
                <div
                  className={
                    idx == imageIndex
                      ? "slide sliderDiv activeSlide"
                      : "slide sliderDiv"
                  }
                >
                  <img
                    src={category.image}
                    width={200}
                    height={300}
                    className="ms-3"
                    alt="image"
                  />
                  <h1 className="me-5">{sessionStorage.getItem("lang")==="ar"?category.nameAr:category.name}</h1>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}
