import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const GuestCartContext = createContext();

export const useGuestCart = () => useContext(GuestCartContext);

export const GuestCartProvider = ({ children }) => {
  const [guestCartData, setGuestCartData] = useState(
    sessionStorage.getItem("cartData")
      ? JSON.parse(sessionStorage.getItem("cartData"))
      : []
  );
  const [guestCartCount, setGuestCartCount] = useState(0);

  useEffect(() => {
    const cartData = sessionStorage.getItem("cartData")
      ? JSON.parse(sessionStorage.getItem("cartData"))
      : [];
    setGuestCartData(cartData);
    setGuestCartCount(cartData.length);
  }, []);

  const updateGuestCartCount = (count) => setGuestCartCount(count);

  const handleUpdateGuestCartItem = (
    productId,
    variantId,
    quantity,
    productData,
    showMessage = true
  ) => {
    const found = guestCartData.findIndex(
      (item) => item.productId === productId && item.variantId === variantId
    );
    if (found < 0) {
      if (quantity === 0) {
        if (showMessage)
          return toast.error("Product does not exists in your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
      }
      axios
        .put("https://kanza-back-end.onrender.com/users/updateCart", {
          products: [
            {
              product: productId,
              variant: variantId,
              quantity: quantity,
            },
          ],
        })
        .then(() => {
          const updatedCartData = [
            {
              productId,
              variantId,
              quantity,
              ...productData,
              variant: productData?.variants?.find(
                (vr) => vr._id === variantId
              ),
            },
            ...guestCartData,
          ];
          sessionStorage.setItem("cartData", JSON.stringify(updatedCartData));
          setGuestCartData(updatedCartData);
          updateGuestCartCount(updatedCartData?.length || 0);
          if (showMessage)
            toast.success("Product added to your cart", {
              hideProgressBar: true,
              autoClose: 3000,
              className: "toster",
            });
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        });
    } else {
      if (quantity === 0) {
        const updatedCartData = guestCartData.filter(
          (item) => item.variantId !== variantId
        );
        sessionStorage.setItem("cartData", JSON.stringify(updatedCartData));
        setGuestCartData(updatedCartData);
        updateGuestCartCount(updatedCartData?.length || 0);
        if (showMessage)
          toast.success("Product removed from your cart", {
            hideProgressBar: true,
            autoClose: 3000,
            className: "toster",
          });
        return;
      }
      const updatedCartData = guestCartData.map((item) => {
        if (item.productId === productId && item.variantId === variantId) {
          item.quantity = quantity;
        }
        return item;
      });
      sessionStorage.setItem("cartData", JSON.stringify(updatedCartData));
      setGuestCartData(updatedCartData);
      updateGuestCartCount(updatedCartData?.length || 0);
      if (showMessage)
        toast.success("Product quantity updated", {
          hideProgressBar: true,
          autoClose: 3000,
          className: "toster",
        });
    }
  };

  return (
    <GuestCartContext.Provider
      value={{ guestCartData, guestCartCount, handleUpdateGuestCartItem }}
    >
      {children}
    </GuestCartContext.Provider>
  );
};
