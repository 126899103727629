import React from "react";
import facebook from "../../Assets/Icons/facebook 1.png";
import instagram from "../../Assets/Icons/instagram 1.png";
import KanzaLogo from "../../Assets/Icons/eng-47.png";
import tiktok from "../../Assets/Icons/tiktok 1.png";
import snapchat from "../../Assets/Images/snapChat.png";
import { Link } from "react-router-dom";
import translate from "../../utils/translate";
export default function Footer() {
  return (
    <>
      <section className="Footer pb-1 overflow-hidden">
        <div className="container py-4">
          <div className="row align-items-center">
            <div className="col-md-2 p-3 me-5 ms-4">
              <div>
                <Link to="/">
                  <img src={KanzaLogo} className="w-75 kanzalogoFooter" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 ms-ms-3" dir="auto">
              <ul className="d-flex justify-content-between footerText">
                <li>
                  <Link to={"/"} className="text-decoration-none">
                  {translate("Home")}
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to={"/shop"}>
                    {translate("Shop")}
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to={"/contactus"}>
                    {translate("Contact US")}
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to={"/termsandpolicy"}>
                    {translate("Terms & Policy")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 justify-content-between me-2">
              <div className="text-center">
                <a
                  href="https://www.facebook.com/kanza.egypt?mibextid=WC7FNe&rdid=QItIbTCQgDJFpkFi"
                  target="_blank"
                >
                  <img src={facebook} alt="facebook" className="me-2" />
                </a>

                <a href="https://www.instagram.com/kanza_eg/" target="_blank">
                  <img src={instagram} alt="instagram" className="me-2" />
                </a>
                <a href="https://www.tiktok.com/@kanzaegworld" target="_blank">
                  <img src={tiktok} alt="tiktok"   className="me-2" />
                </a>
                <a
                  href="https://www.snapchat.com/add/kanza.egypt?share_id=Gt5cQujKvsQ&locale=en-EG"
                  target="_blank"
                >
                  <img src={snapchat} className="me-2" alt="snapchat" />
                </a>
              </div>
            </div>
            <div className="col-md-12 text-center mt-4">
              <p className="footer-main-clr pb-0 mb-0">
                Copyright © 2024 by{" "}
                <a href="https://www.neoneg.com/" target="_blank">
                  NEON{" "}
                </a>
                <a href="https://wa.me/+201113588988" target="-blank">
                  <i className="fa-brands fa-whatsapp cursor-pointer fw-bold red-clr"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
