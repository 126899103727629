import React, { useState } from "react";
import Loginimg from "../../Assets/Images/loginImg.jpg";
import google from "../../Assets/Icons/Group 55370.png";
import Kanza from "../../Assets/Images/kanza logo  1.png";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { Vortex } from "react-loader-spinner";
import toast from "react-hot-toast";
import { googleProvider } from "../Register/firebaseConfg";
import { getAuth, signInWithPopup } from "firebase/auth";
import translate from "../../utils/translate";

export default function Login() {
  const [googleLoading, setgoogleLoading] = useState(false);

  const signInWithGoogle = async () => {
    setgoogleLoading(true);
    try {
      // console.log("test register")
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();

      axios
        .post("https://kanza-back-end.onrender.com/users/verify-google-token", {
          idToken,
        })
        .then((response) => {
          navigate("/");
          toast.success("welcome");
          localStorage.setItem("token", response.data.token);
          setgoogleLoading(false);
        });
    } catch (error) {
      console.error("Error during sign-in:", error);
      setgoogleLoading(false);
    }
  };

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  let validationSchema = Yup.object({
    email: Yup.string().required("email is required").email("email is invalid"),
    password: Yup.string().required("password is requrid "),
  });

  async function loginsubmit(values) {
    setLoading(true);
    let { data } = await axios
      .post(`https://kanza-back-end.onrender.com/users/login`, values)
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
    if (data.token) {
      navigate("/");
      toast.success("welcome");
      localStorage.setItem("token", data.token);
      setLoading(false);
    }
  }

  let formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: loginsubmit,
  });

  return (
    <>
      <section className="py-5 list-group-item-warning mt-5">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-5 px-5 mx-2 ms-5 loginCover">
              <img src={Loginimg} alt="Loginimg" width={500} height={600} />
            </div>
            <div className="col-md-5 boxlogin shadow border-top-0">
              <div className="text-center pt-md-5 mt-md-5">
                <h4 className="h3 fw-bold">
                  {translate("Login to your account")}
                </h4>
                <p className="font-sm ">
                  {translate("Enter to continue and explore within your grasp")}
                </p>
              </div>
              <div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="loginform w-75 mx-auto mb-3"
                >
                  {err ? toast.error(err) : ""}

                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder=" Enter your email"
                    className="form-control mb-3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <div class="error w-100">
                      <div class="error__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          viewBox="0 0 24 24"
                          height="24"
                          fill="none"
                        >
                          <path
                            fill="#393a37"
                            d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                          ></path>
                        </svg>
                      </div>
                      <div class="error__title">{formik.errors.email}</div>
                    </div>
                  ) : (
                    ""
                  )}

                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    className="form-control my-3"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.password && formik.touched.password ? (
                    <div class="error w-100">
                      <div class="error__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          viewBox="0 0 24 24"
                          height="24"
                          fill="none"
                        >
                          <path
                            fill="#393a37"
                            d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                          ></path>
                        </svg>
                      </div>
                      <div class="error__title">{formik.errors.password}</div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 w-100">
                    <div className="loginh3 w-100 text-center my-4">
                      <h3 className="w-100 text-center position-relative fs-5 main-clr-text">
                      {translate("OR")}
                      </h3>
                      {googleLoading ? (
                        <Vortex
                          visible={true}
                          height="20"
                          width="20"
                          ariaLabel="vortex-loading"
                          wrapperStyle={{}}
                          wrapperClass="vortex-wrapper"
                          colors={[
                            "red",
                            "green",
                            "blue",
                            "yellow",
                            "orange",
                            "purple",
                          ]}
                        />
                      ) : (
                        <img
                          src={google}
                          width={50}
                          onClick={signInWithGoogle}
                          className="cursor-pointer"
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 w-100 d-flex justify-content-center">
                    <div className="LoginBtn w-100">
                      {loading ? (
                        <button
                          type="button"
                          className="text-white w-100 py-2 fw-bold"
                        >
                          <Vortex
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="vortex-loading"
                            wrapperStyle={{}}
                            wrapperClass="vortex-wrapper"
                            colors={[
                              "red",
                              "green",
                              "blue",
                              "yellow",
                              "orange",
                              "purple",
                            ]}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="text-white w-100 py-2 fw-bold"
                        >
                        {translate("Login")}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div>
                <Link
                  className="text-decoration-none ForgotPassword"
                  to={"/forgetpassoword"}
                >
                  <p className="me-5 mt-3 pe-2"> {translate("Forgot password?")}</p>
                </Link>
              </div>

              <div className="text-center pt-4 pb-2 ">
                <h6 className="noAccount">
                {translate("Don’t have an account?")}
                  <Link
                    className="text-decoration-none text-dark"
                    to={"/register"}
                  >
                    <span className="fw-bold">  {translate("Sign up")}</span>
                  </Link>{" "}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
