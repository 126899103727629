const translate = (word, lang = (sessionStorage.getItem("lang") || "en")) => {
  if(lang?.toLowerCase() === "en") return word;
  return keywordsArabic[word?.toLowerCase()] || word;
   
}

const keywordsArabic = {
  "login to your account": "تسجيل الدخول إلى حسابك",
  "enter to continue and explore within your grasp": "أدخل للمتابعة والاستكشاف بين يديك",
  "sign up": "إنشاء حساب",
  "don’t have an account?": "ليس لديك حساب؟",
  "forgot password?": "نسيت كلمة المرور؟",
  "login": "تسجيل الدخول",
  "or": "أو",
  "create account": "إنشاء حساب",
  "first name": "الاسم الأول",
  "last name": "الاسم الأخير",
  "enter email or phone": "أدخل البريد الإلكتروني أو الهاتف",
  "password": "كلمة المرور",
  "phone": "الهاتف",
  "register": "تسجيل",
  "do you have account?": "هل لديك حساب؟",
  "sign in": "تسجيل الدخول",
  "where every stitch tells a story.": "حيث كل غرزة تحكي قصة.",
  "slip into style, stand out with confidence!": "ارتدِ الأناقة وتميز بثقة!",
  "our products": "منتجاتنا",
  "sale": "خصم",
  "out of stock": "غير متوفر في المخزون",
  "washing instructions": "تعليمات الغسيل",
  "refund & exchange policies": "سياسات الاسترجاع والتبديل",
  "sustainable fashion": "الموضة المستدامة",
  "your nearest store": "متجرك الأقرب",
  "subscribe to our newsletter": "اشترك في نشرتنا الإخبارية",
  "enter your email": "أدخل بريدك الإلكتروني",
  "subscribe now": "اشترك الآن",
  "about us": "من نحن",
  "mission": "مهمتنا",
  "our mission is to make our customers feel comfortable, confident, and beautiful while wearing our products. as we believe that our clothing reflects our personalities, and we desire that everyone is able to express themselves. additionally, we strive to offer the highest quality products at the most affordable prices.": "مهمتنا هي جعل عملائنا يشعرون بالراحة والثقة والجمال أثناء ارتداء منتجاتنا. نحن نؤمن بأن ملابسنا تعكس شخصياتنا، ونسعى لتمكين الجميع من التعبير عن أنفسهم. بالإضافة إلى ذلك، نسعى لتقديم أفضل المنتجات بجودة عالية وبأسعار معقولة.",
  "vission": "رؤيتنا",
  "history": "تاريخنا",
  "our vision is to be an industry leader, bringing fashion into the modern era while providing the best value to the consumer. our goal is to consistently offer stylish and trendy collections.": "رؤيتنا هي أن نكون روادًا في الصناعة، بإدخال الموضة إلى العصر الحديث مع تقديم أفضل قيمة للمستهلك. هدفنا هو تقديم مجموعات أنيقة وعصرية باستمرار.",
  "the company is a new business resulting from the fusion of three major companies specializing in textile, garment, and clothing manufacturing. it aims to provide youth with fashionable clothing options to buy and wear. as the company's founders, we discovered that the egyptian fashion market is underserved. in fact, most clothing stores sell high-quality items at exorbitant prices. we saw a need for an egyptian brand that offers high-quality, upbeat, fashion-forward clothing at reasonable prices.": "الشركة هي مشروع جديد ناتج عن دمج ثلاث شركات كبرى متخصصة في صناعة المنسوجات والملابس. تهدف إلى توفير خيارات ملابس عصرية للشباب للشراء والارتداء. كمؤسسي الشركة، اكتشفنا أن سوق الموضة المصري لا يحصل على خدمات كافية. في الواقع، معظم متاجر الملابس تبيع منتجات عالية الجودة بأسعار باهظة. رأينا الحاجة إلى علامة تجارية مصرية تقدم ملابس عصرية وعالية الجودة بأسعار معقولة.",
  "nearest stores":"أقرب المتاجر",
  "no stores available":"لا توجد متاجر متاحة",
  "washing instructions":" تعليمات الغسيل",
  "at kanza, we believe in empowering our customers with the knowledge to care for their clothing sustainably. when it comes to our garments, we recommend washing with cold water to preserve both the fabric's integrity and vibrant colors. for optimal results, we advise separating colors, especially during the initial wash. this ensures that any potential color bleeding is minimized, allowing each garment to maintain its original brilliance . following these simple care instructions will help prolong the life of your garment and keep it looking fresh wear after wear.":" في كانزا، نؤمن بتمكين عملائنا بالمعرفة اللازمة للعناية بملابسهم بشكل مستدام. عندما يتعلق الأمر بملابسنا ، نوصي بالغسيل بالماء البارد للحفاظ على سلامة القماش وألوانه النابضة بالحياة. للحصول على أفضل النتائج ، ننصح بفصل الألوان ، خاصة أثناء الغسيل الأولي. هذا يضمن تقليل أي نزيف محتمل للون ، مما يسمح لكل ثوب بالحفاظ على تألقه الأصلي. سيساعد اتباع تعليمات العناية البسيطة هذه على إطالة عمر ملابسك والحفاظ عليها تبدو جديدة بعد ارتدائها.",
  "if you have any questions or concerns, please":" إذا كان لديك أي أسئلة أو استفسارات ، من فضلك",
  "thank you for choosing kanza! we hope you enjoy your shopping experience.":"شكرا لاختيارك كانزا! نأمل أن تستمتع بتجربة التسوق الخاصة بك. ",
  "contact us.": "اتصل بنا.",
  "refund and exchange policies": "سياسات الاسترجاع والاستبدال",
  "by making a purchase on our website, you agree to abide by the terms of our refund and exchange policy.": "عند إجراء عملية شراء على موقعنا، فإنك توافق على الالتزام بشروط سياسة الاسترداد والتبادل الخاصة بنا.",
  "shipping and handling fees are non-refundable, except in cases of damaged or defective items.": "رسوم الشحن والتوصيل غير قابلة للاسترداد، إلا في حالات العناصر التالفة أو المعيبة.",
  "we reserve the right to deny refunds or exchanges for items that do not meet our return policy criteria.": "نحتفظ بالحق في رفض الاسترداد أو التبادل للعناصر التي لا تتوافق مع معايير سياسة الإرجاع لدينا.",
  "refund and exchange exceptions:": "استثناءات الاسترداد والتبادل:",
  "if you received an item as a gift and would like to return or exchange it, please contact our customer support team for assistance. we will be happy to help you with the process.": "إذا تلقيت عنصرًا كهدية وترغب في إرجاعه أو استبداله، يُرجى التواصل مع فريق دعم العملاء لدينا للحصول على المساعدة. سنكون سعداء بمساعدتك في العملية.",
  "gift returns:": "إرجاع الهدايا:",
  "we will arrange for a replacement or issue a refund, including any applicable shipping fees.": "سنقوم بترتيب استبدال العنصر أو إصدار استرداد، بما في ذلك أي رسوم شحن قد تنطبق.",
  "if you receive a damaged or defective item, please contact us immediately upon receipt.": "إذا تلقيت عنصرًا تالفًا أو معيبًا، يُرجى الاتصال بنا فور استلامه.",
  "damaged or defective items:": "العناصر التالفة أو المعيبة:",
  "payments will be returned to the card used or by instapay.": "سيتم إعادة المدفوعات إلى البطاقة المستخدمة أو عبر InstaPay.",
  "payments will take 3-7 business days to be returned.": "ستستغرق المدفوعات من 3 إلى 7 أيام عمل للعودة.",
  "returning payment:": "إرجاع المدفوعات:",
  "upon approval of a refund or exchange, please be aware that the customer is responsible for covering the shipping expenses associated with returning the item.": "عند الموافقة على الاسترداد أو التبادل، يُرجى العلم أن العميل هو المسؤول عن تغطية نفقات الشحن المرتبطة بإرجاع العنصر.",
  "shipping and delivery:": "الشحن والتسليم:",
  "exchanges are based on product availability. if the item you desire be unavailable, we will present alternative choices for your consideration.": "يتم التبادل بناءً على توفر المنتج. إذا كان العنصر المطلوب غير متوفر، سنقدم لك خيارات بديلة للنظر فيها.",
  "exchange item availability:": "توفر عناصر التبادل:",
  "items that have been altered, damaged, or used.": "العناصر التي تم تعديلها أو تلفها أو استخدامها.",
  "items marked as final sale.": "العناصر المُعلَّمة بأنها بيع نهائي.",
  "certain items are not eligible for return or exchange, including:": "بعض العناصر غير مؤهلة للإرجاع أو التبادل، بما في ذلك:",
  "non-returnable items:": "العناصر غير القابلة للإرجاع:",
  "items eligible for exchange must be unused, unworn, and in the same condition as when you received them. they must also be in their original packaging with all tags attached.": "يجب أن تكون العناصر المؤهلة للتبادل غير مستخدمة وغير ملبوسة وفي نفس الحالة التي تلقيتها بها. يجب أن تكون أيضًا في عبوتها الأصلية مع جميع العلامات المرفقة.",
  "we offer exchanges within 14 days of the delivery date.": "نحن نقدم التبادل في غضون 14 يومًا من تاريخ التسليم.",
  "exchange & refund process:": "عملية التبادل والاسترداد:",
  "to initiate a return or exchange, kindly reach out to our customer service team via phone or social media channels. provide them with your order details, including the product name, invoice number, size, and reason for refund or exchange. our team will assist you further.": "لبدء عملية الإرجاع أو التبادل، يرجى التواصل مع فريق خدمة العملاء عبر الهاتف أو قنوات التواصل الاجتماعي. قم بتزويدهم بتفاصيل الطلب الخاصة بك، بما في ذلك اسم المنتج، ورقم الفاتورة، والحجم، وسبب الاسترداد أو التبادل. سيساعدك فريقنا بشكل أكبر.",
  "exchanges:": "التبادلات:",
  "shipping fees is non-refundable.": "رسوم الشحن غير قابلة للاسترداد.",
  "we will issue a refund to the original payment method used for the purchase.": "سنقوم بإصدار استرداد إلى طريقة الدفع الأصلية المستخدمة في الشراء.",
  "refunds will be issued to the original payment method used for the purchase, excluding shipping and handling fees.": "سيتم إصدار استرداد إلى طريقة الدفع الأصلية المستخدمة في الشراء، باستثناء رسوم الشحن والتوصيل.",
  "to be eligible for a refund, items must be unused, unworn, and in the same condition as when you received them. they must also be in their original packaging with all tags attached.": "لتكون مؤهلاً للاسترداد، يجب أن تكون العناصر غير مستخدمة، وغير ملبوسة، وفي نفس الحالة التي تلقيتها بها. يجب أن تكون أيضًا في عبوتها الأصلية مع جميع العلامات المرفقة.",
  "we accept returns for a refund within 14 days of the delivery date.": "نقبل الإرجاع للاسترداد في غضون 14 يومًا من تاريخ التسليم.",
  "refunds:": "الاستردادات:",
  "at kanza, we strive to ensure your complete satisfaction with every purchase. if for any reason you are not satisfied with your order, we offer refunds and exchanges subject to the following policy:": "في Kanza، نسعى لضمان رضاك التام عن كل عملية شراء. إذا لم تكن راضيًا عن طلبك لأي سبب، فإننا نقدم استردادًا وتبادلات وفقًا للسياسة التالية:",
  "sustainable fashion": "الموضة المستدامة",
  "at kanza, we're not just passionate about style; we're committed to sustainability. that's why we're proud to announce that over 90% of our fashion line is crafted with the planet in mind. through innovative dyeing and manufacturing techniques, we've revolutionized our production process to minimize environmental impact while maximizing style. from utilizing eco-friendly dyes to implementing energy-efficient practices, every step of our manufacturing journey is carefully curated to ensure we leave a lighter footprint on the planet. join us in making a fashionable statement while championing sustainability. explore our collection today and discover fashion that looks good and feels even better knowing it's environmentally friendly.": "في Kanza، نحن لا نهتم بالأناقة فحسب؛ بل نحن ملتزمون بالاستدامة. لهذا السبب نفخر بالإعلان أن أكثر من 90٪ من خط أزيائنا مصمم مع مراعاة كوكب الأرض. من خلال تقنيات الصباغة والتصنيع المبتكرة، قمنا بثورة في عملية الإنتاج لدينا لتقليل الأثر البيئي مع زيادة الأناقة. من استخدام الأصباغ الصديقة للبيئة إلى تطبيق ممارسات موفرة للطاقة، يتم اختيار كل خطوة في رحلتنا التصنيعية بعناية لضمان ترك بصمة أقل على الكوكب. انضم إلينا في صنع بيان أنيق مع دعم الاستدامة. استكشف مجموعتنا اليوم واكتشف الموضة التي تبدو رائعة وتشعرك بشكل أفضل لأنها صديقة للبيئة.",
  "contact us information": "معلومات الاتصال بنا",
  "submit": "إرسال",
  "email": "البريد الإلكتروني",
  "name": "الاسم",
  "message": "الرسالة",
  "contact us":"تواصل معنا",
  "new password": "كلمة المرور الجديدة",
  "enter your new password": "أدخل كلمة المرور الجديدة",
  "forget password": "نسيت كلمة المرور ",
  "successfully verify your email address": "تم التحقق من عنوان بريدك الإلكتروني بنجاح",
  "go home": "العودة إلى الصفحة الرئيسية",
  "sale": "خصم",
  "sorry, this item is out of stock!": "عذرًا، هذا المنتج غير متوفر في المخزون!",
  "only:": "فقط:",
  "items left in stock!": "منتجات متبقية في المخزون!",
  "size:": "المقاس:",
  "color:": "اللون:",
  "quantity": "الكمية",
  "add to cart": "أضف إلى السلة",
  "review": "مراجعة",
  "description": "الوصف",
  "customers reviews": "مراجعات العملاء",
  "add your review": "أضف مراجعتك",
  "your rating": "تقييمك",
  "your review": "مراجعتك",
  "enter your review": "أدخل مراجعتك",
  "related products": "منتجات ذات صلة",
  "filter products": "تصفية المنتجات",
  "price range": "نطاق السعر",
  "categories": "الفئات",
  "sub categories": "الفئات الفرعية",
  "filter": "تصفية",
  "no products": "لا توجد منتجات",
  "size": "المقاس",
  "price": "السعر",
  "wishlist": "القائمة المفضله",
  "your wishlist is empty": "القائمة المفضله الخاصة بك فارغة",
  "profile": "الملف الشخصي",
  "settings": "الإعدادات",
  "account details": "تفاصيل الحساب",
  "orders": "الطلبات",
  "addresses": "العناوين",
  "logout":"تسجيل الخروج",
  "your profile": "ملفك الشخصي",
  "change password": "تغيير كلمة المرور",
  "change your password": "تغيير كلمة مرورك",
  "password changes": "تغييرات كلمة المرور",
  "current password": "كلمة المرور الحالية",
  "new password": "كلمة المرور الجديدة",
  "cancel": "إلغاء",
  "no orders yet": "لا توجد طلبات بعد",
  "order details": "تفاصيل الطلب",
  "date": "التاريخ",
  "status": "الحالة",
  "total": "الإجمالي",
  "previous": "السابق",
  "next": "التالي",
  "product name": "اسم المنتج",
  "no address yet": "لا يوجد عنوان بعد",
  "remove": "إزالة",
  "add new address": "إضافة عنوان جديد",
  "select government": "اختر المحافظة",
  "postalcode": "الرمز البريدي",
  "building": "المبنى",
  "street": "الشارع",
  "floor": "الطابق",
  "apartment": "الشقة",
  "address":"العنوان",
  "select country": "اختر الدولة",
  "cart":"عربة التسوق",
  "checkout":"إتمام الشراء",
  "payment":"الدفع",
  "your cart is empty":"عربة التسوق الخاصة بك فارغة",
  "personal data":"البيانات الشخصية",
  "save":"حفظ",
  "edit":"تعديل",
  "total price Before":"السعر الإجمالي قبل",
  "total price After":"السعر الإجمالي بعد",
  "total price":"السعر الإجمالي",
  "sale value":"قيمة الخصم",
  "add promo code":"أضف رمز الخصم",
  "promo code":"رمز الخصم",
  "add loyalty card":"أضف بطاقة الولاء",
  "loyalty card":"بطاقة الولاء",
"select address":"اختر العنوان",
"your address":"عنوانك",
"select your address or add new address":"اختر عنوانك أو أضف عنوانًا جديدًا",
"launch demo modal":"فتح نافذة العرض التجريبية",
"subtotal":"المجموع الفرعي",
"cash on delivery":"الدفع عند الاستلام",
"credit card":"بطاقة ائتمان",
"select payment method":"اختر طريقة الدفع",
"select address first":"اختر العنوان أولاً",
"shipping":"التوصيل",
"terms & policy":"الشروط والسياسة",
"terms and conditions for online fashion brand":"الشروط والأحكام للعلامة التجارية للأزياء عبر الإنترنت",
"welcome to kanza! these terms and conditions govern your use of our website, products, and services. by accessing or using our website, you agree to comply with these terms. please read them carefully.":"مرحبًا بكم في كانزا! تحكم هذه الشروط والأحكام استخدامك لموقعنا الإلكتروني ومنتجاتنا وخدماتنا. باستخدامك لموقعنا، فإنك توافق على الالتزام بهذه الشروط. يرجى قراءتها بعناية.",
"acceptance of terms: by accessing or using our website, you agree to be bound by these terms and conditions. if you do not agree with any part of these terms, you may not access the website or use our services.":"قبول الشروط: باستخدامك لموقعنا، فإنك توافق على الالتزام بهذه الشروط والأحكام. إذا كنت لا توافق على أي جزء من هذه الشروط، فلا يمكنك الوصول إلى الموقع أو استخدام خدماتنا.",
"online store: our website allows you to browse, purchase, and review information about our products. by placing an order through our website, you acknowledge that you are at least 18 years old or have permission from a parent or guardian.":"المتجر الإلكتروني: يتيح لك موقعنا الإلكتروني تصفح المنتجات وشرائها ومراجعة المعلومات المتعلقة بها. بوضع طلب عبر موقعنا، فإنك تقر بأنك تبلغ من العمر 18 عامًا على الأقل أو لديك إذن من أحد الوالدين أو الوصي.",
"product information: we make every effort to display accurate product information, including descriptions, prices, and images. however, we cannot guarantee that all information is accurate, complete, or current. we reserve the right to modify or update product information at any time.":"معلومات المنتج: نبذل قصارى جهدنا لعرض معلومات دقيقة عن المنتجات، بما في ذلك الأوصاف والأسعار والصور. ومع ذلك، لا يمكننا ضمان أن تكون جميع المعلومات دقيقة أو كاملة أو محدثة. نحتفظ بالحق في تعديل أو تحديث معلومات المنتج في أي وقت.",
"orders and payments: when you place an order, you agree to provide accurate and complete information. you authorize us to charge your provided payment method for the total amount of the order, including taxes, shipping, and handling fees. we reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing, or suspected fraud.":"الطلبات والمدفوعات: عندما تقدم طلبًا، فإنك توافق على تقديم معلومات دقيقة وكاملة. وتفوضنا بخصم المبلغ الإجمالي للطلب، بما في ذلك الضرائب ورسوم الشحن والتوصيل من طريقة الدفع التي قدمتها. نحتفظ بالحق في رفض أو إلغاء أي طلب لأي سبب، بما في ذلك توفر المنتج أو أخطاء في التسعير أو الاشتباه في الاحتيال.",
"shipping and delivery: we aim to process and ship orders promptly, but delivery times may vary depending on your location and other factors beyond our control. we are not responsible for delays or errors caused by shipping carriers.":"الشحن والتوصيل: نسعى إلى معالجة وشحن الطلبات بسرعة، ولكن قد تختلف أوقات التسليم حسب موقعك وعوامل أخرى خارجة عن إرادتنا. نحن لسنا مسؤولين عن التأخيرات أو الأخطاء التي تحدث بسبب شركات الشحن.",
"returns and exchanges: we accept returns and exchanges within 14 days of delivery, subject to certain conditions. please review our return policy for more information on eligibility and procedures.":"الإرجاع والاستبدال: نقبل الإرجاع والاستبدال خلال 14 يومًا من التسليم، وفقًا لشروط معينة. يرجى مراجعة سياسة الإرجاع الخاصة بنا لمزيد من المعلومات حول الأهلية والإجراءات.",
"intellectual property: all content on our website, including text, images, logos, and trademarks, is the property of kanza or its licensors and is protected by copyright and other intellectual property laws. you may not use, reproduce, or distribute any content without our prior written permission.":"الملكية الفكرية: جميع المحتويات على موقعنا الإلكتروني، بما في ذلك النصوص والصور والشعارات والعلامات التجارية، هي ملك لشركة كانزا أو الجهات المرخصة لها وتحميها قوانين حقوق النشر وغيرها من قوانين الملكية الفكرية. لا يجوز لك استخدام أو إعادة إنتاج أو توزيع أي محتوى دون إذن كتابي مسبق منا.",
"privacy policy: our privacy policy governs the collection, use, and disclosure of personal information provided by users of our website. by using our website, you consent to the terms of our privacy policy.":"سياسة الخصوصية: تحكم سياسة الخصوصية الخاصة بنا جمع واستخدام والكشف عن المعلومات الشخصية المقدمة من مستخدمي موقعنا الإلكتروني. باستخدامك لموقعنا، فإنك توافق على شروط سياسة الخصوصية الخاصة بنا.",
"limitation of liability: to the fullest extent permitted by law, we disclaim all warranties, express or implied, regarding the accuracy, reliability, or availability of our website or products. we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our website or products.":"تحديد المسؤولية: إلى أقصى حد يسمح به القانون، نخلي مسؤوليتنا عن جميع الضمانات، الصريحة أو الضمنية، المتعلقة بدقة أو موثوقية أو توافر موقعنا الإلكتروني أو منتجاتنا. لن نكون مسؤولين عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة أو تبعية ناتجة عن استخدامك لموقعنا الإلكتروني أو منتجاتنا.",
"changes to terms: we reserve the right to modify or update these terms and conditions at any time without prior notice. by continuing to use our website after any changes, you agree to be bound by the revised terms.":"التغييرات على الشروط: نحتفظ بالحق في تعديل أو تحديث هذه الشروط والأحكام في أي وقت دون إشعار مسبق. باستمرارك في استخدام موقعنا بعد أي تغييرات، فإنك توافق على الالتزام بالشروط المعدلة.",
"about":"من نحن",
"shop":"المتجر",
"home":"الرئيسية"







}


export default translate;