import React, { useEffect, useState } from "react";
import ImgProf from "../../Assets/Images/ProfileImg.png";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { FallingLines, Vortex } from "react-loader-spinner";
import { isMobile } from "mobile-device-detect";
import { useNavigate } from "react-router-dom";
import translate from "../../utils/translate";

export default function AccountDetails() {
  let navigate = useNavigate();
  const [image, setImage] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});

  let validationPassword = Yup.object({
    oldPassword: Yup.string().required("old password is requrid "),
    newPassword: Yup.string().required("new password is requrid "),
  });

  let validationProfile = Yup.object({
    firstName: Yup.string().required("old password is requrid "),
    lastName: Yup.string().required("new password is requrid "),
  });

  async function changepassowrd(values) {
    setLoading(true);
    let { data } = await axios
      .patch(
        `https://kanza-back-end.onrender.com/users/changePassword`,
        values,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setLoading(false);
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  let formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationPassword,
    onSubmit: changepassowrd,
  });

  async function changeName(values) {
    setLoading(true);

    // Create FormData object to send data including the image
    const formData = new FormData();
    formData.append("firstName", values.firstName || profile.firstName);
    formData.append("lastName", values.lastName || profile.lastName);
    formData.append("phone", values.phone || profile.phone);
    if (image) {
      formData.append("file", image);
    }

    try {
      const response = await axios.put(
        `https://kanza-back-end.onrender.com/users/update`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      toast.success(response.data.message);
      getProfileInfo();
      setIsEditing(false);
    } catch (error) {
      setErr(error.response.data.message);
    }

    setLoading(false);
  }

  let formik2 = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
    },
    validationProfile,
    onSubmit: changeName,
  });

  async function getProfileInfo() {
    if (localStorage.getItem("token")) {
      setLoading(true);
      let { data } = await axios.get(
        `https://kanza-back-end.onrender.com/users/profile`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      // console.log(data);
      setProfile(data);
      setLoading(false);
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    getProfileInfo();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading">
          {isMobile ? (
            <FallingLines
              color="#044F5C"
              width="150"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          ) : (
            <FallingLines
              color="#044F5C"
              width="300"
              visible={true}
              ariaLabel="falling-circles-loading"
            />
          )}
        </div>
      ) : (
        <section className="ms-md-5 w-75 ms-3">
          <h5  dir="auto">{translate("Your Profile")} </h5>
          <div className="row">
            <div className="col-md-12">
              <div className="text-center profileImg position-relative  mt-5">
                <img
                  src={profile.image}
                  width={150}
                  height={150}
                  alt="ImgProf"
                />
                {isEditing ? (
                  <>
                    <label htmlFor="file" className=" mb-3">
                      <i className="fa-solid fa-pen edit-img"></i>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </label>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="w-100 mt-4 position-relative">
              <form
                className="d-flex justify-content-between"
                onSubmit={formik2.handleSubmit}
              >
                <input
                  type="text"
                  placeholder={profile.firstName}
                  disabled={!isEditing === true}
                  name="firstName"
                  id="firstName"
                  className={
                    isEditing
                      ? "form-control w-50 me-3"
                      : "form-control w-50 me-3 bg-light"
                  }
                  onChange={formik2.handleChange}
                  onBlur={formik.handleBlur}
                />
                <input
                  type="text"
                  placeholder={profile.lastName}
                  disabled={!isEditing === true}
                  name="lastName"
                  id="lastName"
                  className={
                    isEditing
                      ? "form-control w-50"
                      : "form-control w-50 bg-light"
                  }
                  onChange={formik2.handleChange}
                  onBlur={formik.handleBlur}
                />
                {isEditing ? (
                  <button
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    type="submit"
                    className="btn main-clr text-white mt-3 me-2 position-absolute bottom-0 end-0"
                  >
                    {isEditing ? (
                      <i className="fa-solid fa-check"></i>
                    ) : (
                      <i className="fa-solid fa-user-pen"></i>
                    )}
                  </button>
                ) : (
                  <span
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    className="btn main-clr text-white mt-3 me-2 position-absolute bottom-0 end-0 "
                  >
                    {isEditing ? (
                      <i className="fa-solid fa-check"></i>
                    ) : (
                      <i className="fa-solid fa-user-pen"></i>
                    )}
                  </span>
                )}
              </form>
              <div className="d-flex justify-content-between mt-3">
                <input
                  type="email"
                  placeholder={profile.email}
                  disabled
                  name="Email"
                  id="Email"
                  className="form-control w-50 me-3 bg-light"
                />
                <input
                  type="tel"
                  placeholder={profile.phone || "Enter Your Phone Number"}
                  disabled={!isEditing === true}
                  name="phone"
                  id="phone"
                  className={
                    isEditing
                      ? "form-control w-50"
                      : "form-control w-50 bg-light"
                  }
                  onChange={formik2.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              <Button className="main-clr mt-3" onClick={handleShow}>
               {translate("change password")} 
              </Button>

              <Modal show={show} className="mt-5" onHide={handleClose} >
                <Modal.Header dir="auto" closeButton>
                  <Modal.Title >{translate("change your password")}</Modal.Title>
                </Modal.Header>
                <Modal.Body dir="auto">
                  <form className="pb-3" onSubmit={formik.handleSubmit}>
                    <h5 className="my-4">{translate("Password Changes")}</h5>
                    <input
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      placeholder={translate("Current Password")}
                      className="form-control bg-light mb-3"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.oldPassword && formik.touched.oldPassword ? (
                      <div class="error w-100">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">
                          {formik.errors.oldPassword}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      placeholder={translate("New Password")}
                      className="form-control bg-light mb-3"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.newPassword && formik.touched.newPassword ? (
                      <div class="error w-100">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">
                          {formik.errors.newPassword}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <button className="btn">{translate("Cancel")}</button>
                    {loading ? (
                      <button type="button" className="btn main-clr text-white">
                        <Vortex
                          visible={true}
                          height="30"
                          width="30"
                          ariaLabel="vortex-loading"
                          wrapperStyle={{}}
                          wrapperClass="vortex-wrapper"
                          colors={[
                            "red",
                            "green",
                            "blue",
                            "yellow",
                            "orange",
                            "purple",
                          ]}
                        />
                      </button>
                    ) : (
                      <button type="submit" className="btn main-clr text-white">
                        {translate("change password")}
                      </button>
                    )}{" "}
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
