import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import translate from "../../utils/translate";

export default function NewPassowrd() {
  let { id } = useParams();

  let navigate = useNavigate();

  const [password, setPassword] = useState(null);

  const handelPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  async function postPassword(id) {
    axios
      .patch(`https://kanza-back-end.onrender.com/users/reset/${id}`, {
        password: password,
      })
      .then((response) => {
        // console.log(response.data.token);
        navigate("/login");
      })
      .catch((err) => {
        // console.log(err.response.data.message)
        navigate("/login");
      });
  }

  return (
    <>
      <section>
        <div className="container py-4 mt-md-5">
          <div className="row mt-3 pt-2">
            <div className="col-md-12 main-cover mb-5">
              <h2 className="cover-text">{translate("New Passoword")} </h2>
            </div>

            <div className="w-50 mx-auto py-5 my-5 text-end">
              <input
                type="email"
                className="form-control"
                placeholder={translate("Enter Your New Password")}
                onChange={handelPasswordChange}
              />
              <button
                className="btn main-clr text-white mt-3 mb-5"
                onClick={() => {
                  postPassword(id);
                }}
              >
                {translate("Submit")}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
