import axios from "axios";
import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();
export default function CartContextProvider(props) {
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);
  const [testcategoryNav, setTestCategoryNav] = useState(false);
  const [testcategory2, settestcategory2] = useState(false);

  function getCart() {
    if (localStorage.getItem("token")) {
      return axios
        .get(`https://kanza-back-end.onrender.com/users/cart`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <CartContext.Provider
      value={{
        numberOfCartItems,
        setNumberOfCartItems,
        getCart,
        testcategoryNav,
        setTestCategoryNav,
        testcategory2,
        settestcategory2,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
