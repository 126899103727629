import React from "react";
import mainImg from "../../Assets/Images/Cart Img.png";
import { Link, Outlet, useHref, useNavigate } from "react-router-dom";
import { isMobile } from "mobile-device-detect";
import Dropdown from "react-bootstrap/Dropdown";
import toast from "react-hot-toast";
import axios from "axios";
import translate from "../../utils/translate";

export default function Profile() {
  let navigate = useNavigate();
  let href = useHref();
  // console.log(href);

  function logOut() {
    navigate("/login");
    toast.success("Bye", {
      icon: "😭",
    });

    const tok = localStorage.getItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("shippingfee");
    localStorage.removeItem("priceAfterSale");
    localStorage.removeItem("addressString");
    localStorage.removeItem("priceAftersale");
    axios
      .delete(`https://kanza-back-end.onrender.com/users/logout`, {
        headers: {
          Authorization: tok,
        },
      })
      .then((response) => {})
      .catch((err) => {
        localStorage.removeItem("token");
        localStorage.removeItem("shippingfee");
        localStorage.removeItem("priceAfterSale");
        localStorage.removeItem("addressString");
        localStorage.removeItem("priceAftersale");
      });
  }
  return (
    <>
      <section>
        <div className="container mt-5 pt-5" dir="ltr">
          <div className="row ">
            <div className="col-md-12 main-cover">
              <h2 className="cover-text">{translate("Profile")} </h2>
            </div>
          </div>
          <div className="col-md-9 mx-auto my-5 shadow py-5">
            <div className="row align-items-center justify-content-between">
              {isMobile ? (
                <section className="col-md-12 w-100 ms-2 mb-3">
                  <Dropdown className="mx-auto">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="mb-2 ms-2 main-clr"
                    >
                     {translate("settings")} 
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">
                        {" "}
                        <Link
                          to={"/profile"}
                          className="text-decoration-none text-black d-flex justify-content-start align-items-center"
                        >
                          <div
                            className={
                              href == "/profile"
                                ? "squareIcon main-clr text-white"
                                : "squareIcon"
                            }
                          >
                            <i className="fa-regular fa-circle-user"></i>
                          </div>
                          <h4 className="pt-2 ms-2">{translate("Account Details")}</h4>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        <Link
                          to={"/profile/orders"}
                          className="text-decoration-none text-black d-flex justify-content-start align-items-center"
                        >
                          <div
                            className={
                              href == "/profile/orders"
                                ? "squareIcon main-clr text-white"
                                : "squareIcon"
                            }
                          >
                            <i className="fa-solid fa-bag-shopping"></i>
                          </div>
                          <h4 className="pt-2 ms-2">{translate("Orders")}</h4>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <Link
                          to={"/profile/address"}
                          className="text-decoration-none text-black d-flex justify-content-start align-items-center"
                        >
                          <div
                            className={
                              href == "/profile/address"
                                ? "squareIcon main-clr text-white"
                                : "squareIcon"
                            }
                          >
                            <i className="fa-solid fa-location-dot"></i>
                          </div>
                          <h4 className="pt-1 ms-2">{translate("Addresses")}</h4>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-4">
                        <Link
                          to={"/"}
                          className="text-decoration-none text-black d-flex justify-content-start align-items-center"
                        >
                          <div className="squareIcon">
                            <i className="fa-solid fa-arrow-right-from-bracket"></i>
                          </div>
                          <h4 className="ms-2 pt-1">{translate("Logout")}</h4>
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </section>
              ) : (
                <div className="col-md-3 ms-4 dropdown-items-profile">
                  <div>
                    <Link
                      to={"/profile"}
                      className="text-decoration-none text-black d-flex justify-content-between align-items-center mb-3"
                    >
                      <div
                        className={
                          href == "/profile"
                            ? "squareIcon main-clr text-white"
                            : "squareIcon"
                        }
                      >
                        <i className="fa-regular fa-circle-user"></i>
                      </div>
                      <h4>{translate("Account Details")}</h4>
                    </Link>
                    <Link
                      to={"/profile/orders"}
                      className="text-decoration-none text-black d-flex justify-content-between align-items-center mb-3"
                    >
                      <div
                        className={
                          href == "/profile/orders"
                            ? "squareIcon main-clr text-white"
                            : "squareIcon"
                        }
                      >
                        <i className="fa-solid fa-bag-shopping"></i>
                      </div>
                      <h4 className="me-5 pe-5">{translate("Orders")}</h4>
                    </Link>
                    <Link
                      to={"/profile/address"}
                      className="text-decoration-none text-black d-flex justify-content-between align-items-center mb-3"
                    >
                      <div
                        className={
                          href == "/profile/address"
                            ? "squareIcon main-clr text-white"
                            : "squareIcon"
                        }
                      >
                        <i className="fa-solid fa-location-dot"></i>
                      </div>
                      <h4 className="me-5 pe-5">{translate("Addresses")}</h4>
                    </Link>
                    <Link
                      to={"/login"}
                      className="text-decoration-none text-black d-flex justify-content-between align-items-center mb-3"
                    >
                      <div className="squareIcon">
                        <i className="fa-solid fa-arrow-right-from-bracket"></i>
                      </div>
                      <h4 className="me-5 pe-4" onClick={logOut}>
                      {translate("Logout")}
                      </h4>
                    </Link>
                  </div>
                </div>
              )}
              <div className="col-md-8 border-start">
                <Outlet></Outlet>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
