import React, { useState } from "react";
import registerImg from "../../Assets/Images/registerImg.jpg";
import Kanza from "../../Assets/Images/kanza logo  1.png";
import { Link, useNavigate } from "react-router-dom";
import google from "../../Assets/Icons/Group 55370.png";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { Vortex } from "react-loader-spinner";
import toast from "react-hot-toast";
import { googleProvider } from "./firebaseConfg";
import { getAuth, signInWithPopup } from "firebase/auth";
import translate from "../../utils/translate";

export default function Register() {
  const signInWithGoogle = async () => {
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();
      axios
        .post("https://kanza-back-end.onrender.com/users/verify-google-token", {
          idToken,
        })
        .then((response) => {
          navigate("/profile");
          toast("please Edit and Enter Your Phone Number", {
            duration: 5000,
          });
          localStorage.setItem("token", response.data.token);
        });
    } catch (error) {
      console.error("Error during sign-in:", error);
    }
  };

  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null); // State to store the selected image
  let navigate = useNavigate();

  let validationSchema = Yup.object({
    firstName: Yup.string()
      .required("FirstName is required")
      .min(3, "Minmim 3 letters")
      .max(25, "Maxmin 25 letters"),
    lastName: Yup.string()
      .required("LastName is required")
      .min(3, "Minmim 3 letters")
      .max(25, "Maxmin 25 letters"),
    email: Yup.string().required("email is required").email("email is invalid"),
    password: Yup.string().required("password is requrid "),
    phone: Yup.string()
      .required("must enter your phone number")
      .matches(/^01[0-2]\d{8}$/, "Enter an Egyption phone number"),
  });

  async function rejesterSubmit(values) {
    setLoading(true);

    // Create FormData object to send data including the image
    const formData = new FormData();
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("phone", values.phone);
    if (image) {
      formData.append("file", image);
    }

    try {
      const response = await axios.post(
        `https://kanza-back-end.onrender.com/users/register`,
        formData
      );
      toast.success(response.data.message);
      navigate("/login");
    } catch (error) {
      setErr(error.response.data.message);
      toast.error(error.response.data.message);
    }

    setLoading(false);
  }

  let formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
    },
    validationSchema,
    onSubmit: rejesterSubmit,
  });

  return (
    <>
      <section className="py-5 list-group-item-warning mt-5">
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-5 px-5 mx-2 ms-5 registerCover">
              <img
                src={registerImg}
                alt="Loginimg"
                style={{ minHeight: "710px" }}
                width={500}
                height={670}
              />
            </div>
            <div className="col-md-5 shadow border-top-0 boxregister">
              <div className="text-center pt-md-4 mt-md-5">
                <h4 className="h3 fw-bold pb-3">{translate("Create account")} </h4>
              </div>
              <div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="loginform w-75 mx-auto mb-3"
                >
                  <div className="w-100 d-flex justify-content-center">
                    <label htmlFor="file" className="custum-file-upload mb-3">
                      <div className="icon">
                        <svg
                          viewBox="0 0 24 24"
                          fill=""
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                              fill=""
                            ></path>{" "}
                          </g>
                        </svg>
                      </div>
                      <input
                        type="file"
                        name="file"
                        id="file"
                        accept="image/*"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </label>
                  </div>
                  <div className="d-flex">
                    <div className="d-flex flex-column w-100 ">
                      <div className="input-group"></div>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder={translate("First name")}
                        className="form-control w-100 ps-3 me-3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.firstName && formik.touched.firstName ? (
                        <div class="error w-100 my-2 me-4">
                          <div class="error__icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              viewBox="0 0 24 24"
                              height="24"
                              fill="none"
                            >
                              <path
                                fill="#393a37"
                                d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                              ></path>
                            </svg>
                          </div>
                          <div class="error__title">
                            {formik.errors.firstName}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex flex-column w-100">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder={translate("Last name")}
                        className="form-control w-100 ps-3"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.lastName && formik.touched.lastName ? (
                        <div class="error w-100 my-2">
                          <div class="error__icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              viewBox="0 0 24 24"
                              height="24"
                              fill="none"
                            >
                              <path
                                fill="#393a37"
                                d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                              ></path>
                            </svg>
                          </div>
                          <div class="error__title">
                            {formik.errors.lastName}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="position-relative">
                    <label htmlFor="email"></label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={translate("Enter email or phone")}
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <div class="error w-100 mt-2">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">{formik.errors.email}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="position-relative">
                    <label htmlFor="password"></label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder={translate("Password")}
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <div class="error w-100 mt-2">
                        <div class="error__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            viewBox="0 0 24 24"
                            height="24"
                            fill="none"
                          >
                            <path
                              fill="#393a37"
                              d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                            ></path>
                          </svg>
                        </div>
                        <div class="error__title">{formik.errors.password}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <label htmlFor="phone"></label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder={translate("Phone")}
                    className="form-control mb-4"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.phone && formik.touched.phone ? (
                    <div class="error w-100 mt-2">
                      <div class="error__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          viewBox="0 0 24 24"
                          height="24"
                          fill="none"
                        >
                          <path
                            fill="#393a37"
                            d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
                          ></path>
                        </svg>
                      </div>
                      <div class="error__title">{formik.errors.phone}</div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-12 w-100">
                    <div className="loginh3 w-100 text-center my-4">
                      <h3 className="w-100 text-center position-relative fs-5 main-clr-text">
                        {translate("OR")}
                      </h3>
                      <img
                        src={google}
                        width={50}
                        className="cursor-pointer"
                        onClick={() => {
                          signInWithGoogle();
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12 w-100 d-flex justify-content-center">
                    <div className="LoginBtn w-100">
                      {loading ? (
                        <button
                          type="button"
                          className="text-white w-100 py-2 fw-bold"
                        >
                          <Vortex
                            visible={true}
                            height="30"
                            width="30"
                            ariaLabel="vortex-loading"
                            wrapperStyle={{}}
                            wrapperClass="vortex-wrapper"
                            colors={[
                              "red",
                              "green",
                              "blue",
                              "yellow",
                              "orange",
                              "purple",
                            ]}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="text-white w-100 py-2 fw-bold"
                        >
                          {translate("Register")}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>

              <div className="text-center pt-4 pb-4 ">
                <h6 className="noAccount">
                  {translate("Do you have account?")}
                  <Link
                    className="text-decoration-none text-dark"
                    to={"/login"}
                  >
                    <span className="fw-bold cursor-pointer">{translate("Sign in")} </span>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
