import React, { useEffect, useState } from "react";
import CategorySlider from "../CategorySlider/CategorySlider";
import Offer from "../Offer/Offer";
import OurProducts from "../OurProducts/OurProducts";
import ServicesAndPayment from "../ServicesAndPayment/ServicesAndPayment";
import ReviewsSlider from "../ReviewsSlider/ReviewsSlider";
import Subscribe from "../Subscribe/Subscribe";
import HomeBanner from "../HomeBanner/HomeBanner";
import translate from "../../utils/translate";

export default function Home() {
  return (
    <>
      <section className="pt-3">
        <HomeBanner />
      </section>

      <section className="overflow-hidden review"  dir="auto">
        <div className="container sec-contanier py-3">
          <h3 className="text-center pt-1">
            {translate("Where every stitch tells a story.")}
          </h3>
          <p className="text-center fw-bold">
            {translate("Slip into style, stand out with confidence!")}
          </p>
          <CategorySlider />
        </div>
      </section>

      <Offer />

      <OurProducts />

      <ServicesAndPayment />

      <ReviewsSlider />
      <Subscribe />
    </>
  );
}
