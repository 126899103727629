import React from 'react'
import { Link } from 'react-router-dom'
import translate from '../../utils/translate'

export default function Refund() {
  return <>
  <section>
            <div className='container py-5 mt-md-4'  dir="auto">
                <div className="row mt-md-4">
                    <div className="col-md-12 main-cover">
                        <h2 className='cover-text text-center'> {translate("Refund and Exchange Policies")} 
                        </h2>
                    </div>
                    <div className="col-md-12">
                    <p className='text-center'>{translate("At Kanza, we strive to ensure your complete satisfaction with every purchase. If for any reason you are not satisfied with your order, we offer refunds and exchanges subject to the following policy:")}</p>
                        <ol>
                            <li> <strong>{translate("Refunds:")}</strong>  <br />
                            - {translate("We accept returns for a refund within 14 days of the delivery date.")} <br />
                            - {translate("To be eligible for a refund, items must be unused, unworn, and in the same condition as when you received them. They must also be in their original packaging with all tags attached.")} <br />
                            - {translate("Refunds will be issued to the original payment method used for the purchase, excluding shipping and handling fees.")} <br />
                            - {translate("We will issue a refund to the original payment method used for the purchase.")} <br />
                            - {translate("Shipping fees is non-refundable.")}
                            </li>
                            <li><strong>{translate("Exchanges:")}</strong> <br />
                            - {translate("To initiate a return or exchange, kindly reach out to our customer service team via phone or social media channels. Provide them with your order details, including the product name, invoice number, size, and reason for refund or exchange. Our team will assist you further.")}
                            </li>
                            <li><strong>{translate("Exchange & Refund Process:")}</strong> <br />
                            - {translate("We offer exchanges within 14 days of the delivery date.")} <br />
                            - {translate("Items eligible for exchange must be unused, unworn, and in the same condition as when you received them. They must also be in their original packaging with all tags attached.")}
                            </li>
                            <li><strong>{translate("Non-Returnable Items:")}</strong> <br />
                            - {translate("Certain items are not eligible for return or exchange, including:")} <br />
                            - {translate("Items marked as final sale.")} <br />
                            - {translate("Items that have been altered, damaged, or used.")}
                            </li>
                            <li><strong>{translate("Exchange Item Availability:")}</strong> <br />
                            - {translate("Exchanges are based on product availability. If the item you desire be unavailable, we will present alternative choices for your consideration.")}
                            </li>
                            <li><strong>{translate("Shipping and Delivery:")}</strong> <br />
                            - {translate("Upon approval of a refund or exchange, please be aware that the customer is responsible for covering the shipping expenses associated with returning the item.")}
                            </li>
                            <li><strong>{translate("Returning Payment:")} </strong> <br />
                            - {translate("Payments will take 3-7 business days to be returned.")}  <br />
                            - {translate("Payments will be returned to the card used or by InstaPay.")}
                            </li>
                            <li><strong>{translate("Damaged or Defective Items:")}</strong> <br />
                            - {translate("If you receive a damaged or defective item, please contact us immediately upon receipt.")}  <br />
                            - {translate("We will arrange for a replacement or issue a refund, including any applicable shipping fees.")}
                            </li>
                            <li><strong>{translate("Gift Returns:")}</strong> <br />
                            - {translate("If you received an item as a gift and would like to return or exchange it, please contact our customer support team for assistance. We will be happy to help you with the process.")}
                            </li>
                            <li><strong>{translate("Refund and Exchange Exceptions:")}</strong> <br />
                            - {translate("We reserve the right to deny refunds or exchanges for items that do not meet our return policy criteria.")} <br />
                            - {translate("Shipping and handling fees are non-refundable, except in cases of damaged or defective items.")}
                             </li>




                        </ol>
                        <p className=' fw-bold mb-0 mt-5'>{translate("By making a purchase on our website, you agree to abide by the terms of our Refund and Exchange Policy.")}</p>
                        <strong>{translate("If you have any questions or concerns, please")}<Link className='text-black' to={'/contactus'}> {translate("contact us.")}</Link></strong>
                        <p className='fw-bold mb-5'>{translate("Thank you for choosing Kanza! We hope you enjoy your shopping experience.")}</p>
                    </div>
                </div>
            </div>
        </section>
  </>
}
