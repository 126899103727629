import React, { useContext, useEffect, useRef, useState } from "react";
import searchButton from "../../Assets/Icons/search-interface-symbol.png";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/Icons/eng-47.png";
import cartIcon from "../../Assets/Icons/cart.png";
import heartIcon from "../../Assets/Icons/hearticon.png";
import userIcon from "../../Assets/Icons/user.png";
import axios from "axios";
import toast from "react-hot-toast";
import { CartContext } from "../Context/userContext";
import { useGuestCart } from "../Context/GuestCartContext";
import { Offcanvas } from "react-bootstrap";
import translate from "../../utils/translate";
import "../../App.css";
export default function Navbar() {
  // let token = localStorage.getItem('token')
  let { guestCartCount } = useGuestCart();

  let navigate = useNavigate();

  let { numberOfCartItems, setNumberOfCartItems } = useContext(CartContext);

  async function getCartProducts() {
    if (localStorage.getItem("token")) {
      let { data } = await axios.get(
        `https://kanza-back-end.onrender.com/users/cart`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      setNumberOfCartItems(data?.cart?.length);
    }
  }
  function logOut() {
    navigate("/login");
    toast.success("Bye", {
      icon: "😭",
    });

    const tok = localStorage.getItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("shippingfee");
    localStorage.removeItem("priceAfterSale");
    localStorage.removeItem("addressString");
    localStorage.removeItem("priceAftersale");
    axios
      .delete(`https://kanza-back-end.onrender.com/users/logout`, {
        headers: {
          Authorization: tok,
        },
      })
      .then((response) => {})
      .catch((err) => {
        localStorage.removeItem("token");
        localStorage.removeItem("shippingfee");
        localStorage.removeItem("priceAfterSale");
        localStorage.removeItem("addressString");
        localStorage.removeItem("priceAftersale");
      });
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getCartProducts();
    }
  }, []);

  const [category, setCategory] = useState([]);

  async function getcategoies() {
    let { data } = await axios.get(
      `https://kanza-back-end.onrender.com/products/categories`
    );
    setCategory(data.categories);
  }
  useEffect(() => {
    getcategoies();
  }, []);

  useEffect(() => {
    document.addEventListener("click", function (event) {
      let isClickInsideNavbar = document
        .querySelector(".navbar")
        .contains(event.target);
      let isMobileViewport = window.innerWidth <= 768;
      if (!isClickInsideNavbar && isMobileViewport) {
        let navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler.getAttribute("aria-expanded") === "true") {
          navbarToggler.click();
        }
      }
    });

    document.querySelectorAll(".navbar-nav .nav-item").forEach(function (link) {
      link.addEventListener("click", function () {
        let isMobileViewport = window.innerWidth <= 768;
        if (isMobileViewport) {
          let navbarToggler = document.querySelector(".navbar-toggler");
          if (navbarToggler.getAttribute("aria-expanded") === "true") {
            navbarToggler.click();
          }
        }
      });
    });
  }, []);
  const [selectedLang, setSelectedLang] = useState(
    sessionStorage.getItem("lang") || "en"
  );

  useEffect(() => {
    // Update sessionStorage whenever selectedLang changes
    sessionStorage.setItem("lang", selectedLang);
  }, [selectedLang]);

  // Handle language change
  const handleLangChange = (event) => {
    window.location.reload();
    setSelectedLang(event.target.value);
  };
  const getSelectedLangText = () => {
    if (selectedLang === "en") return "English";
    if (selectedLang === "ar") return "Arabic";
  };
  return (
    <>
      <section className="fixed-top ">
        <div className="navbrdr " dir="auto">
          <div className="container text-white d-flex justify-content-between">
            <p>
              {translate("Contact Us")} : {""}
              <a
                className="text-decoration-none text-white"
                href="tel:01157547852"
              >
                01157547852
              </a>
            </p>
            <p>
              {translate("Email")} :
              <a
                className="text-decoration-none text-white"
                href="mailto:kanzaegworld@gmail.com"
              >
                {""} kanzaegworld@gmail.com
              </a>
            </p>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={logo} className="logo" alt="Kanza logo" />
            </Link>
            <button
              className="navbar-toggler"
              id="test"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse ms-md-5"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0 d-flex justify-content-around w-50 mx-sm-auto mt-2">
                <li className="nav-item">
                  <Link
                    className="text-decoration-none main-clr-text2 fw-bold"
                    to={"/"}
                  >
                    <p>{translate("Home")}</p>
                  </Link>
                </li>
                <li className="nav-item shop">
                  <Link
                    className="text-decoration-none main-clr-text2 fw-bold"
                    to={"/shop"}
                  >
                    <p>{translate("Shop")}</p>
                  </Link>
                  <div className="dropdown-content">
                    <ul className="row list-unstyled">
                      {category.map((category, index) => (
                        <>
                          <Link
                            state={category}
                            to={`/shop`}
                            className="text-decoration-none text-black"
                          >
                            <li key={index} className="cursor-pointer col-12">
                              {sessionStorage.getItem("lang") === "ar"
                                ? category.nameAr
                                : category.name}
                            </li>
                          </Link>
                        </>
                      ))}
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    className="text-decoration-none main-clr-text2 fw-bold"
                    to={"/about"}
                  >
                    <p>{translate("About")}</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="text-decoration-none main-clr-text2 fw-bold"
                    to={"/contactus"}
                  >
                    <p>{translate("Contact Us")}</p>
                  </Link>
                </li>
              </ul>
              {localStorage.getItem("token") ? (
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navIcons align-items-center">
                  <li className="nav-item cursor-pointer pe-3">
                    <Link to={"/wishlist"}>
                      <img src={heartIcon} alt="heartIcon" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/cart"}>
                      <li className="nav-item cursor-pointer pe-3 position-relative cartCounter">
                        {numberOfCartItems === 0 ? (
                          ""
                        ) : (
                          <div className="numOfCartItems text-black">
                            {numberOfCartItems}
                          </div>
                        )}
                        <img src={cartIcon} alt="cartIcon" />
                      </li>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/profile"}>
                      <li className="nav-item cursor-pointer">
                        <img src={userIcon} alt="userIcon" />
                      </li>
                    </Link>
                  </li>
                  <li className="nav-item ps-3 pt-1">
                    <li onClick={logOut} className="nav-item cursor-pointer">
                      <i className="fa-solid fa-right-from-bracket main-clr-text2 fs-5"></i>
                    </li>
                  </li>
                  {/* <li className="nav-item">
                    <div
                      className="select"
                      data-default="English"
                      data-one="English"
                      data-two="Arabic"
                    >
                      <div className="selected">
                        {getSelectedLangText()}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                          className="arrow"
                        >
                          <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                      </div>
                      <div className="options">
                        <div title="English">
                          <input
                            id="english"
                            name="lang-option"
                            type="radio"
                            value="en"
                            checked={selectedLang === "en"}
                            onChange={handleLangChange}
                          />
                          <label
                            className="option"
                            htmlFor="english"
                            data-txt="English"
                          ></label>
                        </div>
                        <div title="Arabic">
                          <input
                            id="arabic"
                            name="lang-option"
                            type="radio"
                            value="ar"
                            checked={selectedLang === "ar"}
                            onChange={handleLangChange}
                          />
                          <label
                            className="option"
                            htmlFor="arabic"
                            data-txt="Arabic"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
              ) : (
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navIcons  align-items-center">
                  {localStorage.getItem("token") ? (
                    <li className="nav-item">
                      <Link to={"/cart"}>
                        <li className="nav-item cursor-pointer pe-3 position-relative cartCounter">
                          {numberOfCartItems === 0 ? (
                            ""
                          ) : (
                            <div className="numOfCartItems text-black">
                              {numberOfCartItems}
                            </div>
                          )}
                          <img src={cartIcon} alt="cartIcon" />
                        </li>
                      </Link>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <Link to={"/cart"}>
                        <li className="nav-item cursor-pointer pe-3 position-relative cartCounter">
                          {guestCartCount === 0 ? (
                            ""
                          ) : (
                            <div className="numOfCartItems text-black">
                              {guestCartCount}
                            </div>
                          )}
                          <img src={cartIcon} alt="cartIcon" />
                        </li>
                      </Link>
                    </li>
                  )}

                  <li className="nav-item">
                    <Link to={"/login"}>
                      <li className="nav-item cursor-pointer">
                        <img src={userIcon} alt="userIcon" />
                      </li>
                    </Link>
                  </li>
                  
                </ul>
              )}
              <div className="d-flex justify-content-center align-items-center ">
                    <div
                      className="select"
                      data-default="English"
                      data-one="English"
                      data-two="Arabic"
                    >
                      <div className="selected d-flex align-items-center">
                        {getSelectedLangText()}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                          className="arrow"
                        >
                          <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                        </svg>
                      </div>
                      <div className="options">
                        <div title="English">
                          <input
                            id="english"
                            name="lang-option"
                            type="radio"
                            value="en"
                            checked={selectedLang === "en"}
                            onChange={handleLangChange}
                          />
                          <label
                            className="option"
                            htmlFor="english"
                            data-txt="English"
                          ></label>
                        </div>
                        <div title="Arabic">
                          <input
                            id="arabic"
                            name="lang-option"
                            type="radio"
                            value="ar"
                            checked={selectedLang === "ar"}
                            onChange={handleLangChange}
                          />
                          <label
                            className="option"
                            htmlFor="arabic"
                            data-txt="Arabic"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </nav>
      </section>

      <section>
        <div
          className="modal fade w-100 mt-5 pt-5"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content mt-md-4 mt-0 pt-0 ">
              <div className="modal-body  w-100 border-0 w-100 position-relative ">
                <input
                  type="text"
                  className=" form-control"
                  placeholder="search here"
                />
                <i className="fa-search fa-solid position-absolute top-0 end-0 me-4 mt-4 cursor-pointer"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <button
        type="button"
        id="demo"
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        {translate("Launch demo modal")}
      </button>
    </>
  );
}
